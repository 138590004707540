"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FactorService = void 0;
var factors_1 = require("../fixtures/factors");
var _ = __importStar(require("lodash"));
var func_1 = require("../react/util/func");
var chartConfig_1 = require("./chartConfig");
var comparisonConfig_1 = require("./comparisonConfig");
var SourceSwedishMapping = {
    survey: "Enkätsvar",
    statistics: "Statistik",
    ranking: "Ranking"
};
/*
* Future improvement: Since the FactorSlug is an argument to all methods it might be an idea to add an wrapper where the
* FactorSlug is fixed. This would avoid us having to pass both the service and the FactorSlug as a parameter in a lot
* of places.  */
var FactorService = /** @class */ (function () {
    function FactorService(factors) {
        if (factors === void 0) { factors = factors_1.Factors; }
        this.factors = factors;
        this.factorFromSlug = _.keyBy(factors, function (f) { return f.slug; });
    }
    FactorService.prototype.validatedLookup = function (o, factorSlug, property) {
        var value = o[factorSlug];
        if (!value) {
            throw new Error(property + " not found for slug " + factorSlug);
        }
        return value[property];
    };
    FactorService.prototype.getFactor = function (factorSlug) {
        return this.factorFromSlug[factorSlug];
    };
    FactorService.prototype.getName = function (factorSlug) {
        return this.factorFromSlug[factorSlug].name;
    };
    FactorService.prototype.isRespondentTypeApplicable = function (factorSlug, chartingLabel) {
        var excludedEndpoints = ['SurveyFactorBothMulti', 'SurveySimpleBarchartCombined'];
        return !_.includes(excludedEndpoints, this.getDataEndpoint(factorSlug, chartingLabel));
    };
    FactorService.prototype.getSource = function (factorSlug) {
        return this.validatedLookup(this.factorFromSlug, factorSlug, 'source');
    };
    // Ideally translations should not go here. Move at will.
    FactorService.prototype.getSourceTranslated = function (factorSlug) {
        return SourceSwedishMapping[this.getSource(factorSlug)];
    };
    FactorService.prototype.getApiName = function (factorSlug) {
        return this.validatedLookup(this.factorFromSlug, factorSlug, 'api_name');
    };
    FactorService.prototype.getChartingLabels = function (factorSlug) {
        var charting = this.validatedLookup(this.factorFromSlug, factorSlug, 'chartingArray');
        var labels = charting === null || charting === void 0 ? void 0 : charting.map(function (c) { return c.label; });
        return labels ? labels.filter(func_1.isDefined) : [];
    };
    FactorService.prototype.getCharting = function (factorSlug, label) {
        var chartingArray = this.validatedLookup(this.factorFromSlug, factorSlug, 'chartingArray');
        if (func_1.isDefined(chartingArray)) {
            if (label) {
                // if a label is provided we need to match it
                var theOneMatchingLabel = _.find(chartingArray, function (c) { return c.label === label; });
                if (theOneMatchingLabel) {
                    return theOneMatchingLabel;
                }
                else {
                    throw Error("Factor slug: \"" + factorSlug + "\". No chart config matching label " + label + " found.");
                }
            }
            else {
                // if no label is required, we just pick the first (analogous to old behavior with single chart config)
                var first = chartingArray[0], rest = chartingArray.slice(1);
                if (_.isEmpty(rest)) {
                    return first;
                }
                else {
                    throw Error("Factor slug: \"" + factorSlug + "\". Need to provide a charting label. Valid are: " + chartingArray.map(function (c) { return c.label; }).join(", "));
                }
            }
        }
        // fallback to just return an empty charting
        return {};
    };
    FactorService.prototype.getChartDataDataTransformer = function (factorCharting) {
        var _a;
        var ruleName = factorCharting === null || factorCharting === void 0 ? void 0 : factorCharting.chartDataTransformerRule;
        if (factorCharting === null || factorCharting === void 0 ? void 0 : factorCharting.chartDataTransformerRule) {
            var transformer = (_a = _.find(factors_1.ChartDataTransformerRules, function (rule) { return rule.ruleName === ruleName; })) === null || _a === void 0 ? void 0 : _a.transformer;
            if (!transformer) {
                throw new Error('No corresponding transformer to ' + ruleName);
            }
            return transformer;
        }
        return _.identity;
    };
    FactorService.prototype.getChartConfig = function (factorSlug, chartingLabel) {
        var _a;
        return new chartConfig_1.ChartConfig((_a = this.getCharting(factorSlug, chartingLabel)) === null || _a === void 0 ? void 0 : _a.chart);
    };
    FactorService.prototype.getComparisonConfig = function (factorSlug, label) {
        var _a;
        return new comparisonConfig_1.ComparisonConfig((_a = this.getCharting(factorSlug, label)) === null || _a === void 0 ? void 0 : _a.factorComparison);
    };
    FactorService.prototype.getDataEndpoint = function (factorSlug, chartingLabel) {
        var dataEndpoint = this.getChartConfig(factorSlug, chartingLabel).defaultChartDataEndpointName;
        if (dataEndpoint) {
            return dataEndpoint;
        }
        if (!dataEndpoint && this.getSource(factorSlug) === 'survey') {
            // default survey chart shows both respondents types
            return 'SurveyFactorBothMulti';
        }
        return 'MunicipalityFactorValues';
    };
    FactorService.prototype.getFactorDescription = function (factorSlug) {
        var _a;
        return (_a = this.validatedLookup(this.factorFromSlug, factorSlug, 'description')) !== null && _a !== void 0 ? _a : '';
    };
    FactorService.prototype.getScaleDescription = function (factorSlug, label) {
        var _a;
        var gradingScaleType = (_a = this.getComparisonConfig(factorSlug, label)) === null || _a === void 0 ? void 0 : _a.gradingScaleType;
        return gradingScaleType
            ? factors_1.gradingScaleTypeDescription[gradingScaleType].scaleDescription
            : undefined;
    };
    FactorService.prototype.isRankingFactor = function (factorSlug) {
        return this.validatedLookup(this.factorFromSlug, factorSlug, 'ranking');
    };
    return FactorService;
}());
exports.FactorService = FactorService;
