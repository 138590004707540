"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_1 = require("react");
var factorService_1 = require("../../services/factorService");
var presentableDataClient_1 = require("../clients/presentableDataClient");
var factors_1 = require("../../fixtures/factors");
var factorChartComponent_1 = require("./charting/factorChartComponent");
var reactModalHelper_1 = require("./helpers/reactModalHelper");
var municipalityComparison_1 = require("./comparison/municipalityComparison");
var top5Comparison_1 = require("./comparison/top5Comparison");
var skrComparison_1 = require("./comparison/skrComparison");
var factorChartHelper_1 = require("./helpers/factorChartHelper");
var groupingService_1 = require("../../services/groupingService");
var grouping_1 = require("../../fixtures/grouping");
var RankingChartWidget = function (props) {
    reactModalHelper_1.setReactModalAppElement();
    var factorService = React.useState(function () { return new factorService_1.FactorService(factors_1.Factors); })[0];
    var dataClientFactory = React.useState(function () { return presentableDataClient_1.presentableDataClientFactory(props.apiBaseUrl, factorService); })[0];
    var groupingService = React.useState(function () { return new groupingService_1.GroupingService(grouping_1.Grouping); })[0];
    var factorSlug = props.initialFactorSlug;
    var comparisonConfig = factorService.getComparisonConfig(factorSlug);
    var _a = municipalityComparison_1.useMunicipalityComparison([props.initialMunicipality]), comparisonMunicipalities0 = _a.comparisonMunicipalities, comparisonMunicipalitiesDispatch = _a.comparisonMunicipalitiesDispatch, selectedMunicipalities0 = _a.selectedMunicipalities, selectedMunicipalitiesDispatch = _a.selectedMunicipalitiesDispatch, reset = _a.reset;
    var _b = react_1.useState('top'), activeComponent = _b[0], setActiveComponent = _b[1];
    var _c = factorChartHelper_1.filterMunicipalitiesBasedOnFactor(comparisonConfig, comparisonMunicipalities0, selectedMunicipalities0), comparisonMunicipalities = _c.comparisonMunicipalities, selectedMunicipalities = _c.selectedMunicipalities;
    return React.createElement(React.Fragment, null,
        React.createElement(municipalityComparison_1.MunicipalityComparison, { disabled: comparisonConfig.comparisonDisabled, initialMunicipalities: [props.initialMunicipality], includeCounties: !comparisonConfig.lacksCountyData, excludeSweden: comparisonConfig.excludeSweden, active: activeComponent, selectedMunicipalities: selectedMunicipalities, selectedMunicipalitiesDispatch: selectedMunicipalitiesDispatch, comparisonMunicipalities: comparisonMunicipalities, reset: reset, presetComparisonComponents: ['top', 'SKR'], top5ComparisonNode: React.createElement(top5Comparison_1.Top5Comparison, { active: activeComponent, setActive: setActiveComponent, activeFactor: factorSlug, isBiggerBetter: comparisonConfig.isBiggerBetter, respondentType: undefined, comparisonMunicipalitiesDispatch: comparisonMunicipalitiesDispatch, groupingService: groupingService }), skrComparisonNode: React.createElement(skrComparison_1.SKRComparison, { active: activeComponent, setActive: setActiveComponent, activeMunicipality: props.initialMunicipality, comparisonMunicipalitiesDispatch: comparisonMunicipalitiesDispatch }) }),
        React.createElement("div", { className: "chart-wrapper" },
            React.createElement(factorChartComponent_1.FactorChartComponent, { factorSlug: props.initialFactorSlug, municipalities: selectedMunicipalities, respondentType: undefined, dataEndpoint: 'MunicipalityFactorValues', dataClientFactory: dataClientFactory, factorService: factorService, width: 620 })));
};
// rails-react needs a default export in order to be able to find a component
// noinspection JSUnusedGlobalSymbols
exports.default = RankingChartWidget;
