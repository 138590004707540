"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toLineChartYearData = void 0;
var lineChartYearFactory_1 = require("./factories/lineChartYearFactory");
var chartData_1 = require("../../models/external/chartData");
var _ = __importStar(require("lodash"));
var converterUtil_1 = require("./converterUtil");
function toLineChartYearData(chartData, chartService) {
    var _a, _b;
    var attributes = chartData.attributes, data = chartData.data;
    var datadesc = attributes.datadesc;
    var indexOfYear = _.findIndex(datadesc, function (cdd) { return cdd.name === chartData_1.DataDescriptionYearName; });
    var seriesLegendNames = _.map(datadesc, function (cdd) { return cdd.legend; });
    var seriesData = _.map(data.data, function (entry) {
        var _a;
        var year = entry[indexOfYear].toString();
        var yearLabel = chartService.yearLabelTransformer(year);
        var yearField = (_a = {}, _a[lineChartYearFactory_1.Year] = yearLabel, _a);
        var zipped = _.zip(seriesLegendNames, entry);
        var dataSeriesFields = {};
        _.forEach(zipped, function (_a, index) {
            var seriesName = _a[0], value = _a[1];
            if (value && index !== indexOfYear) {
                dataSeriesFields[seriesName] = value;
            }
        });
        return __assign(__assign({}, yearField), dataSeriesFields);
    });
    // Used for making the bullet of the last year data point special
    var latestYear = _.chain(seriesData).map(function (item) { return item.year; }).max().value();
    var outerBulletCircleOpacityFieldName = lineChartYearFactory_1.LineChartYearFactory.OUTER_BULLET_CIRCLE_OPACITY_FIELD_NAME;
    var outerBulletCircleOpacityVisibleValue = lineChartYearFactory_1.LineChartYearFactory.OUTER_BULLET_CIRCLE_OPACITY_VISIBLE_VALUE;
    seriesData = _.chain(seriesData)
        .map(function (item) {
        var _a, _b;
        return item.year === latestYear
            ? __assign(__assign({}, item), (_a = {}, _a[outerBulletCircleOpacityFieldName] = outerBulletCircleOpacityVisibleValue, _a)) : __assign(__assign({}, item), (_b = {}, _b[outerBulletCircleOpacityFieldName] = 0.0, _b));
    })
        .value();
    if (chartService.dashYears) {
        var dashYears_1 = (_a = chartService.dashYears) === null || _a === void 0 ? void 0 : _a.map(function (year) { return "" + year; });
        // Used for being able to signify a "broken" data series by dotting it
        seriesData = _.chain(seriesData)
            .map(function (item) {
            var _a, _b, _c, _d;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            var itemUpdated = _.includes(dashYears_1, item.year)
                ? __assign(__assign({}, item), (_a = {}, _a[lineChartYearFactory_1.LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY] = "6,3", _a)) : __assign(__assign({}, item), (_b = {}, _b[lineChartYearFactory_1.LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY] = "", _b));
            // Brittle hack for working around missing dashed line issue when there are gaps in series
            var dashYearsPoliticians = dashYears_1 && _.flatMap(dashYears_1, function (dy) { return [dy, "" + (parseInt(dy) + 1)]; });
            itemUpdated = _.includes(dashYearsPoliticians, itemUpdated.year)
                ? __assign(__assign({}, itemUpdated), (_c = {}, _c[lineChartYearFactory_1.LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY_POLITICIAN] = "6,3", _c)) : __assign(__assign({}, itemUpdated), (_d = {}, _d[lineChartYearFactory_1.LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY_POLITICIAN] = "", _d));
            return itemUpdated;
        })
            .value();
    }
    var unitExcludeFilter = chartService.unitExcludeFilter;
    var seriesAttributes = converterUtil_1.seriesAttributesFromDataDesc(datadesc, function (index) { return index !== indexOfYear; }, function (unit) { return unitExcludeFilter(unit); });
    var title = (_b = chartService.titleOverride) !== null && _b !== void 0 ? _b : attributes.title;
    return { data: seriesData, seriesAttributes: seriesAttributes, title: title, sources: attributes.sources, category: lineChartYearFactory_1.Year };
}
exports.toLineChartYearData = toLineChartYearData;
