"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var factorService_1 = require("../../services/factorService");
var presentableDataClient_1 = require("../clients/presentableDataClient");
var factors_1 = require("../../fixtures/factors");
var factorChartComponent_1 = require("./charting/factorChartComponent");
var reactModalHelper_1 = require("./helpers/reactModalHelper");
var SurveyFactorBothChartWidget = function (props) {
    reactModalHelper_1.setReactModalAppElement();
    var factorService = React.useState(function () { return new factorService_1.FactorService(factors_1.Factors); })[0];
    var dataClientFactory = React.useState(function () { return presentableDataClient_1.presentableDataClientFactory(props.apiBaseUrl, factorService); })[0];
    var chartComponentFactory = function (overrideProps) { return React.createElement(factorChartComponent_1.FactorChartComponent, __assign({ factorSlug: props.initialFactorSlug, municipalities: [props.initialMunicipality], respondentType: undefined, dataEndpoint: 'SurveyFactorBoth', dataClientFactory: dataClientFactory, factorService: factorService }, overrideProps)); };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "chart-wrapper" }, chartComponentFactory({ width: 620 })));
};
// rails-react needs a default export in order to be able to find a component
// noinspection JSUnusedGlobalSymbols
exports.default = SurveyFactorBothChartWidget;
