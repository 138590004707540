"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartConfig = void 0;
var ChartConfig = /** @class */ (function () {
    function ChartConfig(chart) {
        this.chart = chart;
    }
    Object.defineProperty(ChartConfig.prototype, "defaultChartDataEndpointName", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.defaultChartDataEndpointName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "titleOverride", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.titleOverride;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "year", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.year;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "years", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.years;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "dashYears", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.dashYears;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "extraLabelWidth", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.extraLabelWidth;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "extraHeight", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.extraHeight;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "isRankingChart", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.chart) === null || _a === void 0 ? void 0 : _a.isSpecialInvertedRankingChart) !== null && _b !== void 0 ? _b : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "sorting", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.sorting;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "seriesIsIntegerData", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.isIntegerData;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "truncate", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.truncate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "unit", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.unit;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "xScales", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.xscales;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "yScales", {
        get: function () {
            var _a;
            return (_a = this.chart) === null || _a === void 0 ? void 0 : _a.yscales;
        },
        enumerable: false,
        configurable: true
    });
    ChartConfig.prototype.createExcludeFilter = function (excludeString) {
        if (excludeString) {
            return function (input) { return input.toLowerCase().indexOf(excludeString.toLowerCase()) === -1; };
        }
        else {
            return function () { return true; };
        }
    };
    Object.defineProperty(ChartConfig.prototype, "sourceExcludeFilter", {
        get: function () {
            var _a;
            return this.createExcludeFilter((_a = this.chart) === null || _a === void 0 ? void 0 : _a.sourceExclude);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ChartConfig.prototype, "unitExcludeFilter", {
        get: function () {
            var _a;
            return this.createExcludeFilter((_a = this.chart) === null || _a === void 0 ? void 0 : _a.unitExclude);
        },
        enumerable: false,
        configurable: true
    });
    ChartConfig.prototype.getYearLabelTransformer = function (yearLabelTransformerName) {
        switch (yearLabelTransformerName) {
            case 'UF': {
                return function (label) {
                    var intYear = parseInt(label);
                    return intYear + "/" + (intYear + 1);
                };
            }
            default: return function (label) { return label; };
        }
    };
    Object.defineProperty(ChartConfig.prototype, "yearLabelTransformer", {
        get: function () {
            var _a;
            return this.getYearLabelTransformer((_a = this.chart) === null || _a === void 0 ? void 0 : _a.yearLabelTransformerName);
        },
        enumerable: false,
        configurable: true
    });
    return ChartConfig;
}());
exports.ChartConfig = ChartConfig;
