"use strict";
// export interface Function {
//     mname: string;
// }
Object.defineProperty(exports, "__esModule", { value: true });
exports.withMName = void 0;
/**
 * Sets the property mname to the method name of the method being decorated.
 *
 * Poor mans workaround for Function.name being empty for method names when targeting ES5. Some more info:
 * https://github.com/microsoft/TypeScript/issues/5611
 * */
// Need to have any here...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withMName(_, name, desc) {
    desc.value.mname = name;
}
exports.withMName = withMName;
