"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modalCustomStyles = exports.modalContentDimensions = void 0;
exports.modalContentDimensions = {};
var widthPercentage = 90;
var heightPercentage = 90;
var maxWidth = 1200;
var maxHeight = Math.round(maxWidth / 1.33);
// workaround for crazy blur issue due to uneven numbers (only solves maxHeight issue unfortunately)
// https://stackoverflow.com/questions/14677490/blurry-text-after-using-css-transform-scale-in-chrome
// https://www.blackpepper.co.uk/blog/blurry-css-animations-in-chrome-try-this
maxHeight = maxHeight % 2 !== 0 ? maxHeight - 1 : maxHeight;
exports.modalCustomStyles = {
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(51, 65, 78, .8)'
    },
    content: {
        border: '0',
        borderRadius: '4px',
        bottom: 'auto',
        minHeight: '500px',
        height: heightPercentage + "%",
        maxHeight: maxHeight + "px",
        left: '50%',
        padding: '2rem 2rem 3rem 2rem',
        position: 'fixed',
        right: 'auto',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        minWidth: '20rem',
        width: widthPercentage + "%",
        maxWidth: maxWidth + "px",
        backgroundColor: '#fff'
    }
};
