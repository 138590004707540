"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuideBoxesComponent = exports.VimeoBox = exports.GuideBox = void 0;
var React = __importStar(require("react"));
var guides_1 = require("../../../fixtures/guides");
var _ = __importStar(require("lodash"));
var NonCheckListLinksArea = function (_a) {
    var guideEntry = _a.guideEntry;
    return React.createElement("div", { className: "links" },
        React.createElement("div", { className: "item" },
            React.createElement("a", { className: "download", href: guideEntry.downloadUrl }, "Ladda\u00A0ner\u00A0guide")));
};
var ChecklistLinksArea = function (_a) {
    var guideEntry = _a.guideEntry, link = _a.link, checklistLink = _a.checklistLink;
    return React.createElement("div", { className: "links" },
        React.createElement("div", { className: "item" },
            checklistLink && React.createElement("a", { className: "download", href: checklistLink }, "Ladda\u00A0ner\u00A0checklista"),
            React.createElement("a", { className: "view-more", href: "" + link }, "Se\u00A0checklista")),
        React.createElement("div", { className: "item" },
            React.createElement("a", { className: "download", href: guideEntry.downloadUrl }, "Ladda\u00A0ner\u00A0guide")));
};
exports.GuideBox = function (props) {
    var guide = props.guide, link = props.link;
    return (React.createElement("div", { className: "box" },
        React.createElement("div", { className: "content" },
            React.createElement("p", { className: "title" },
                React.createElement("a", { href: "" + link }, guide.title)),
            React.createElement("div", { className: "grid" },
                React.createElement("p", null, guide.description),
                React.createElement("a", { href: guide.downloadUrl },
                    React.createElement("img", { src: "/assets/" + guide.thumbUrl, height: "280" })))),
        props.renderLinksArea()));
};
exports.VimeoBox = function (_a) {
    var guide = _a.guide;
    var description = React.createElement(React.Fragment, null, _.map(guide.description, function (description, i) { return React.createElement("p", { key: i }, description); }));
    return (React.createElement("div", { className: "box" },
        React.createElement("div", { className: "content" },
            React.createElement("p", { className: "title" }, guide.title),
            description,
            React.createElement("div", { className: "video" },
                React.createElement("iframe", { frameBorder: "0", scrolling: "no", src: "//player.vimeo.com/video/" + guide.vimeoId + "?dnt=1", title: guide.title, allowFullScreen: true })),
            React.createElement("p", null, "Medverkande: " + guide.videoCredits))));
};
exports.GuideBoxesComponent = function (props) {
    var guideSlugToLink = props.guideSlugToLink, guideSlugToChecklistLink = props.guideSlugToChecklistLink;
    var boxComponents = function (guideSlugs) {
        return _.map(guideSlugs, function (guideSlug) {
            if (guides_1.NonChecklistGuideSlug.guard(guideSlug)) {
                return (React.createElement(exports.GuideBox, { key: guideSlug, guide: guides_1.Guides[guideSlug], link: guideSlugToLink[guideSlug], renderLinksArea: function () { return React.createElement(NonCheckListLinksArea, { guideEntry: guides_1.Guides[guideSlug] }); } }));
            }
            else if (guides_1.ChecklistGuideSlug.guard(guideSlug)) {
                return (React.createElement(exports.GuideBox, { key: guideSlug, guide: guides_1.Guides[guideSlug], link: guideSlugToLink[guideSlug], renderLinksArea: function () {
                        return React.createElement(ChecklistLinksArea, { guideEntry: guides_1.Guides[guideSlug], link: guideSlugToLink[guideSlug], checklistLink: guideSlugToChecklistLink === null || guideSlugToChecklistLink === void 0 ? void 0 : guideSlugToChecklistLink[guideSlug] });
                    } }));
            }
            else if (guides_1.VimeoGuideSlug.guard(guideSlug)) {
                return (React.createElement(exports.VimeoBox, { key: guideSlug, guide: guides_1.Guides[guideSlug] }));
            }
            else {
                return null;
            }
        });
    };
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "guider-boxes large" }, boxComponents(guides_1.GuideSlug.values)));
};
// rails-react needs a default export in order to be able to find a component
// noinspection JSUnusedGlobalSymbols
exports.default = exports.GuideBoxesComponent;
