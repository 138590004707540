"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuideChartComponent = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var municipalityBarChartFactory_1 = require("./factories/municipalityBarChartFactory");
var XYChartComponent_1 = require("./XYChartComponent");
var guides_1 = require("../../../fixtures/guides");
var _ = __importStar(require("lodash"));
var guidesService_1 = require("../../../services/guidesService");
var guidesDataClient_1 = require("../../clients/guidesDataClient");
exports.GuideChartComponent = function (props) {
    var guidesService = react_1.useState(function () { return new guidesService_1.GuidesService(guides_1.Guides); })[0];
    var _a = react_1.useState(), guidesData = _a[0], setGuidesData = _a[1];
    react_1.useEffect(function () {
        new guidesDataClient_1.GuidesDataClient()
            .getGuidesMainData(props.selectedMunicipalities)
            .then(function (data) { return setGuidesData(data); });
    }, [props.selectedMunicipalities]);
    if (!guidesData)
        return null;
    var chartFactories = [];
    _.forOwn(guidesData, function (dataArray, guideSlug) {
        if (dataArray === undefined || !guides_1.GuideSlug.guard(guideSlug))
            return;
        var categoryKey = "guideTitle";
        var seriesNames = [];
        var data = _.map(dataArray.data, function (dataEntry) {
            var _a;
            seriesNames.push.apply(seriesNames, _.keys(dataEntry));
            return __assign((_a = {}, _a[categoryKey] = guidesService.getTitle(guideSlug), _a), dataEntry);
        });
        var seriesAttributes = _.uniq(seriesNames).map(function (sn) { return ({ seriesName: sn, seriesUnit: 'Antal' }); });
        var barChartFactoryProps = {
            category: categoryKey,
            data: data,
            // exportMenuElemId: "",
            seriesAttributes: seriesAttributes,
            categoryAxisTitle: guidesService.getTitle(guideSlug),
            maxValue: dataArray.maxValue
        };
        var chartFactory = new municipalityBarChartFactory_1.MunicipalityBarChartFactory(barChartFactoryProps);
        chartFactories.push(chartFactory);
    });
    return React.createElement(XYChartComponent_1.XYChartComponent, { chartFactory: chartFactories, height: 325 });
};
