"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarkSubstringMatches = void 0;
var React = __importStar(require("react"));
// return typing any due to:
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/27805
// eslint-disable-next-line @typescript-eslint/no-explicit-any
exports.MarkSubstringMatches = function (_a) {
    var str = _a.str, subStrings = _a.subStrings;
    // only mark first hit for simplicity
    for (var _i = 0, subStrings_1 = subStrings; _i < subStrings_1.length; _i++) {
        var markMe = subStrings_1[_i];
        var matchSegments = [];
        var regexp = RegExp(markMe, "gi");
        var match = void 0;
        var key = 0;
        var lastMatchIndex = -1;
        while ((match = regexp.exec(str)) !== null) {
            // add non-matching segments to list
            if (lastMatchIndex === -1 /* first */) {
                if (match.index !== 0) {
                    // if match doesn't begin at index 0 we add the first non match segment to list
                    var firstNonMatchSegment = str.substring(0, match.index);
                    matchSegments.push(React.createElement(React.Fragment, { key: key++ }, firstNonMatchSegment));
                }
            }
            else {
                var lastNonMatch = str.substring(lastMatchIndex, match.index);
                matchSegments.push(React.createElement(React.Fragment, { key: key++ }, lastNonMatch));
            }
            // add matching segment to list with distinct style
            var matchStr = str.substring(match.index, regexp.lastIndex);
            matchSegments.push(React.createElement("u", { key: key++ }, matchStr));
            lastMatchIndex = regexp.lastIndex;
        }
        if (lastMatchIndex != -1 && regexp.lastIndex !== str.length) {
            var suffix = str.substring(lastMatchIndex);
            matchSegments.push(React.createElement(React.Fragment, { key: key++ }, suffix));
        }
        if (matchSegments.length !== 0) {
            return React.createElement("span", null, matchSegments);
        }
    }
    return str;
};
