"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountyComparison = void 0;
var React = __importStar(require("react"));
var counties_1 = require("../../../fixtures/counties");
var _ = __importStar(require("lodash"));
exports.CountyComparison = function (_a) {
    var includeCounties = _a.includeCounties, active = _a.active, setActive = _a.setActive, activeMunicipality = _a.activeMunicipality, comparisonMunicipalitiesDispatch = _a.comparisonMunicipalitiesDispatch;
    var county = _.findKey(counties_1.Counties.county, function (municipalities) { return _.includes(municipalities, activeMunicipality); });
    var municipalities = [];
    var countyName;
    if (!county) {
        console.error("Couldn't find municipality " + activeMunicipality + " in any county. ");
    }
    else {
        municipalities = counties_1.Counties.county[county];
        countyName = _.first(municipalities);
        if (!includeCounties) {
            municipalities = _.tail(municipalities);
        }
    }
    var onClick = function () {
        setActive('county');
        comparisonMunicipalitiesDispatch(['SET', municipalities]);
    };
    // set municipalities once at initialization
    React.useEffect(function () {
        if (active === 'county') {
            comparisonMunicipalitiesDispatch(['SET', municipalities]);
        }
    });
    return (React.createElement("li", { className: active === 'county' ? 'active' : '' },
        React.createElement("a", { onMouseDown: onClick },
            countyName,
            React.createElement("i", { className: "fas fa-chevron-" + (active === 'county' ? "up" : "down") }))));
};
