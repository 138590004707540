"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedMunicipalitiesNonPartitioned = exports.MunicipalityComparison = exports.useMunicipalityComparison = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var autoComplete_1 = require("../listing/autoComplete");
var counties_1 = require("../../../fixtures/counties");
var _ = __importStar(require("lodash"));
var factorChartWidgetReducers_1 = require("../factorChartWidgetReducers");
var react_responsive_1 = require("react-responsive");
// letting return type be inferred here
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useMunicipalityComparison(initialMunicipality) {
    // comparison municipalities
    var comparisonReducer = React.useState(function () { return factorChartWidgetReducers_1.municipalityReducer(initialMunicipality, "comparison"); })[0];
    var _a = react_1.useReducer(comparisonReducer, []), comparisonMunicipalities = _a[0], comparisonMunicipalitiesDispatch = _a[1];
    // selected municipalities
    var initialSelected = function () { return typeof initialMunicipality === 'string' ? [initialMunicipality] : initialMunicipality; };
    var selectedReducer = React.useState(function () { return factorChartWidgetReducers_1.municipalityReducer(initialMunicipality, "selected"); })[0];
    var _b = react_1.useReducer(selectedReducer, initialSelected()), selectedMunicipalities = _b[0], selectedMunicipalitiesDispatch = _b[1];
    var reset = function () {
        comparisonMunicipalitiesDispatch(['SET', []]);
        selectedMunicipalitiesDispatch(['SET', initialSelected()]);
    };
    return {
        comparisonMunicipalities: comparisonMunicipalities, comparisonMunicipalitiesDispatch: comparisonMunicipalitiesDispatch,
        selectedMunicipalities: selectedMunicipalities, selectedMunicipalitiesDispatch: selectedMunicipalitiesDispatch,
        reset: reset
    };
}
exports.useMunicipalityComparison = useMunicipalityComparison;
exports.MunicipalityComparison = function (_a) {
    var disabled = _a.disabled, initialMunicipalities = _a.initialMunicipalities, includeCounties = _a.includeCounties, excludeSweden = _a.excludeSweden, active = _a.active, selectedMunicipalities = _a.selectedMunicipalities, selectedMunicipalitiesDispatch = _a.selectedMunicipalitiesDispatch, comparisonMunicipalities = _a.comparisonMunicipalities, reset = _a.reset, presetComparisonComponents = _a.presetComparisonComponents, top5ComparisonNode = _a.top5ComparisonNode, countyComparisonNode = _a.countyComparisonNode, skrComparisonNode = _a.skrComparisonNode;
    var _b = React.useState(false), visible = _b[0], setVisible = _b[1];
    var allAlternatives = includeCounties
        ? (excludeSweden ? counties_1.CountiesAndMunicipalities : counties_1.CountiesAndMunicipalities.concat(counties_1.Sweden))
        : (excludeSweden ? counties_1.Municipalities : counties_1.Municipalities.concat(counties_1.Sweden));
    var toggleVisible = function (e) {
        e.preventDefault();
        setVisible(!visible);
    };
    var onSelect = function (municipality) {
        selectedMunicipalitiesDispatch(['ADD', [municipality]]);
        setVisible(false);
    };
    var onDeselect = function (municipality) {
        selectedMunicipalitiesDispatch(['REMOVE', [municipality]]);
        setVisible(false);
    };
    var resetMunicipalities = function () {
        selectedMunicipalitiesDispatch(['SET', initialMunicipalities]);
        setVisible(false);
    };
    var unExpandOnEscape = function (e) {
        if (e.key === "Escape")
            setVisible(false);
    };
    var showComp = function (pcs) { return _.includes(presetComparisonComponents, pcs); };
    var isMobile = react_responsive_1.useMediaQuery({ query: '(max-width: 767px)' });
    var selectedComp = React.createElement(exports.SelectedMunicipalitiesNonPartitioned, { selectedMunicipalities: selectedMunicipalities, comparisonMunicipalities: comparisonMunicipalities, onSelect: onSelect, onDeselect: onDeselect, reset: resetMunicipalities });
    return (React.createElement("div", { className: "chart-container" },
        React.createElement("div", { className: "js-chart-selection", id: "chart-selection-survey" },
            React.createElement("div", { className: "compare js-municipality-selection " + (disabled ? "disabled" : ""), onKeyDown: unExpandOnEscape },
                visible
                    ? React.createElement("div", { className: "municipalities" },
                        React.createElement("span", null,
                            React.createElement(SelectedMunicipalitiesPartitioned, { selectedMunicipalities: selectedMunicipalities, comparisonMunicipalities: [], onSelect: onSelect, onDeselect: onDeselect, reset: resetMunicipalities }),
                            React.createElement(autoComplete_1.AutoComplete, { placeHolder: "Lägg till jämförelsekommun", allAlternatives: allAlternatives, isMatchFunction: autoComplete_1.startsWithMatchFunction, onSelect: onSelect })),
                        React.createElement("span", null,
                            React.createElement("i", { className: "fas fa-chevron-up", onClick: toggleVisible })))
                    : React.createElement("div", { className: "prompt js-prompt TODO" },
                        React.createElement("a", { href: "#", onClick: toggleVisible },
                            "V\u00E4lj fler kommuner f\u00F6r att j\u00E4mf\u00F6ra",
                            React.createElement("i", { className: "fas fa-chevron-down" }))),
                !_.isEmpty(presetComparisonComponents) &&
                    React.createElement("div", { className: "options js-options", style: { display: (visible ? "block" : "none") } },
                        React.createElement("ul", { className: "presets " + (!showComp('county') && !showComp('SKR') ? 'only-one' : '') },
                            showComp('top') && top5ComparisonNode,
                            isMobile && active === 'top' && selectedComp,
                            showComp('county') && countyComparisonNode,
                            isMobile && active === 'county' && selectedComp,
                            showComp('SKR') && skrComparisonNode,
                            isMobile && active === 'SKR' && selectedComp),
                        !isMobile && React.createElement("div", { className: "municipalities" }, selectedComp),
                        reset && React.createElement("a", { className: "clear-all js-clear-all", href: "#", onMouseDown: reset }, "Rensa alla val"))))));
};
var SelectedMunicipalitiesPartitioned = function (_a) {
    var selectedMunicipalities = _a.selectedMunicipalities, comparisonMunicipalities = _a.comparisonMunicipalities, onSelect = _a.onSelect, onDeselect = _a.onDeselect;
    var i = 0;
    var selected = selectedMunicipalities.map(function (sm) {
        return React.createElement("li", { key: i++ },
            React.createElement("a", { className: "selected", onMouseDown: function () { return onDeselect(sm); } }, sm));
    });
    var comparison = comparisonMunicipalities.map(function (cm) {
        return React.createElement("li", { key: i++, onMouseDown: function () { return onSelect(cm); } },
            React.createElement("a", { className: "suggestion" }, cm));
    });
    return React.createElement("ul", { className: "selected js-selected" }, selected.concat(comparison));
};
exports.SelectedMunicipalitiesNonPartitioned = function (_a) {
    var selectedMunicipalities = _a.selectedMunicipalities, comparisonMunicipalities = _a.comparisonMunicipalities, onSelect = _a.onSelect, onDeselect = _a.onDeselect;
    var i = 0;
    var comparison = comparisonMunicipalities.map(function (m) {
        return _.includes(selectedMunicipalities, m)
            ? React.createElement("li", { key: i++ },
                React.createElement("a", { className: "selected", onMouseDown: function () { return onDeselect(m); } }, m))
            : React.createElement("li", { key: i++, onMouseDown: function () { return onSelect(m); } },
                React.createElement("a", { className: "suggestion" }, m));
    });
    return (React.createElement("div", { className: "municipalities" },
        React.createElement("ul", { className: "selected js-selected" }, comparison)));
};
