"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComparisonConfig = void 0;
var ComparisonConfig = /** @class */ (function () {
    function ComparisonConfig(factorComparison) {
        this.factorComparison = factorComparison;
    }
    Object.defineProperty(ComparisonConfig.prototype, "isApplicableToPoliticians", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.applicableToPoliticians) !== null && _b !== void 0 ? _b : true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "isBiggerBetter", {
        get: function () {
            var _a;
            return (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.isBiggerBetter;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "isSingleDimensionComparable", {
        get: function () {
            var _a, _b;
            // explicitly not comparable
            var comparable = (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.singleDimensionComparable;
            // or, not using grading scale
            var withoutGradingScale = (_b = this.factorComparison) === null || _b === void 0 ? void 0 : _b.withoutGradingScale;
            return comparable !== undefined ? comparable : withoutGradingScale !== true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "comparisonDisabled", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.disabled) !== null && _b !== void 0 ? _b : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "lacksCountyData", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.lacksCountyData) !== null && _b !== void 0 ? _b : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "excludeSweden", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.excludeSweden) !== null && _b !== void 0 ? _b : false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "includeSwedenByDefault", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.includeSwedenByDefault) !== null && _b !== void 0 ? _b : true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "gradingScaleType", {
        get: function () {
            var _a;
            return (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.gradingScaleType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ComparisonConfig.prototype, "withoutGradingScale", {
        get: function () {
            var _a;
            return (_a = this.factorComparison) === null || _a === void 0 ? void 0 : _a.withoutGradingScale;
        },
        enumerable: false,
        configurable: true
    });
    return ComparisonConfig;
}());
exports.ComparisonConfig = ComparisonConfig;
