"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListGroup = void 0;
var listItem_1 = require("./listItem");
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var filterList_1 = require("../lib/filterList");
exports.ListGroup = function (props) {
    var groupName = props.groupName, itemProps = props.itemProps, filterList = props.filterList;
    var _a = React.useState(_.some(itemProps, function (f) { return f.isSelected; })), isExpanded = _a[0], setExpanded = _a[1];
    React.useEffect(function () {
        var groupExpanded = (filterList_1.isFilteringActive(filterList) && _.some(itemProps, function (f) { return f.isVisible; })) ||
            _.some(itemProps, function (f) { return f.isSelected; });
        setExpanded(groupExpanded);
    }, [filterList, itemProps]);
    var toggleExpanded = function () { return setExpanded(function (isExpanded) { return !isExpanded; }); };
    var isGroupVisible = filterList_1.isFilteringActive(props.filterList)
        ? _.some(itemProps, function (f) { return f.isVisible; })
        : true;
    return React.createElement("ul", { className: "factor-selection-list-group" },
        React.createElement("li", { className: "factor-selection-list-group-name " + (isGroupVisible ? '' : 'hidden'), onClick: toggleExpanded },
            groupName,
            React.createElement("i", { className: "fas fa-chevron-" + (isExpanded ? "down" : "right") + " " + (isExpanded ? "expanded" : ""), "aria-hidden": "true" })),
        itemProps.map(function (listItemProps, i) {
            return React.createElement(listItem_1.ListItem, __assign({ key: i }, listItemProps, { isVisible: listItemProps.isVisible && isGroupVisible && isExpanded }));
        }));
};
