"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupingService = void 0;
var _ = __importStar(require("lodash"));
var GroupingService = /** @class */ (function () {
    function GroupingService(grouping) {
        var _this = this;
        this.grouping = grouping;
        this.getFactorsFromGroup0 = _.memoize(function (groupName) {
            return _.reduce(_this.grouping[groupName], function (factorsGroup, entry) {
                if (entry.hidden == true) {
                    return factorsGroup;
                }
                else {
                    factorsGroup[entry.name] = entry.factors;
                    return factorsGroup;
                }
            }, {});
        });
    }
    GroupingService.prototype.getFactorsFromGroup = function (groupName) {
        return this.getFactorsFromGroup0(groupName);
    };
    GroupingService.prototype.getFactorGroup = function (factorSlug) {
        var isFactorInGroup = function (groupingEntries) {
            return _.some(groupingEntries, function (ge) { return _.includes(ge.factors, factorSlug); });
        };
        var groupName = _.findKey(this.grouping, isFactorInGroup);
        if (groupName) {
            return groupName;
        }
        else {
            throw Error("Unknown group for factor: " + factorSlug);
        }
    };
    return GroupingService;
}());
exports.GroupingService = GroupingService;
