/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "core-js/stable";
import "regenerator-runtime/runtime";

import {enableAllPlugins} from 'immer';
enableAllPlugins();

// main script entry point
import "../../assets/javascripts/typescript/statistics";

// phone hamburger menu event listener
import "../../assets/javascripts/typescript/hamburger";

// jquery etc export to window
import "../../assets/javascripts/typescript/environment";

// ranking table extensions (button showing all years etc)
import "../../assets/javascripts/typescript/rankingTableExensions";

// UNCOMMENT TO ENABLE why-did-you-render - Needs to be imported before any React component
// why-did-you-render bootstrapper
import "../../assets/javascripts/typescript/react/components/whyDidYouRenderBootstrapper";

// ranking widget
import "../../assets/javascripts/typescript/react/components/ranking/rankingWidget";

// guider widget
import "../../assets/javascripts/typescript/react/components/guides/guidesChartWidget";
import "../../assets/javascripts/typescript/react/components/guides/guideCheckbox";
import "../../assets/javascripts/typescript/react/components/guides/guidePieSmall";

// factor chart widget
import "../../assets/javascripts/typescript/react/components/factorChartWidget";

// factor chart widget
import "../../assets/javascripts/typescript/react/components/surveyFactorBothChartWidget";

// ranking chart widget
import "../../assets/javascripts/typescript/react/components/rankingChartWidget";

// chart widget
import "../../assets/javascripts/typescript/react/components/chartWidget";

// header municipality search widget
import "../../assets/javascripts/typescript/react/components/headerMunicipalitySearch";

// faq widget
import "../../assets/javascripts/typescript/faq";

// Support component names relative to this directory:
var componentRequireContext = require.context("../../assets/javascripts/typescript/react/components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
