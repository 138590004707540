"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.MunicipalityBarChartFactory = exports.Municipality = void 0;
var charts_1 = require("@amcharts/amcharts4/charts");
var chartTheme_1 = require("../chartTheme");
var abstractXYChartFactory_1 = require("./abstractXYChartFactory");
var barChartFactory_1 = require("./barChartFactory");
var util_1 = require("./util");
exports.Municipality = 'municipality';
/** Used for showing guides progress of municipalities. */
var MunicipalityBarChartFactory = /** @class */ (function (_super) {
    __extends(MunicipalityBarChartFactory, _super);
    function MunicipalityBarChartFactory(props) {
        return _super.call(this, props) || this;
    }
    MunicipalityBarChartFactory.prototype.defaultChartType = function () { return charts_1.ColumnSeries; };
    MunicipalityBarChartFactory.prototype.reverseXY = function () { return false; };
    MunicipalityBarChartFactory.prototype.createChart = function (chartElemIdOrContainer) {
        var chart = _super.prototype.createChart.call(this, chartElemIdOrContainer);
        chart.cursor.disabled = true;
        return chart;
    };
    MunicipalityBarChartFactory.prototype.createSeries = function (chart, seriesAttribute, SeriesClass) {
        var series = _super.prototype.createSeries.call(this, chart, seriesAttribute, SeriesClass);
        if (series.tooltip)
            series.tooltip.disabled = true;
        return series;
    };
    MunicipalityBarChartFactory.prototype.configAxes = function (categoryAxis, valueAxis) {
        _super.prototype.configAxes.call(this, categoryAxis, valueAxis);
        // cell padding left/right
        categoryAxis.renderer.cellStartLocation = 0.1;
        categoryAxis.renderer.cellEndLocation = .9;
        if (typeof this.props.categoryAxisTitle === 'string') {
            // disable label if title is set
            categoryAxis.renderer.labels.template.disabled = true;
            categoryAxis.title.text = this.props.categoryAxisTitle;
            categoryAxis.title.fontWeight = "500";
            categoryAxis.title.fontFamily = "Montserrat";
            categoryAxis.title.fontSize = "14px";
            categoryAxis.title.fill = chartTheme_1.PrimaryTextColor;
            categoryAxis.title.maxWidth = categoryAxis.maxWidth;
            // 2 rows of text, need to align all charts to longest text one
            categoryAxis.title.minHeight = 40;
            categoryAxis.title.marginTop = 10;
            // categoryAxis.background.fill = color("red");
            // categoryAxis.title.background.fill = color("green");
            categoryAxis.title.wrap = true;
            categoryAxis.renderer.opposite = false;
        }
        // https://www.amcharts.com/docs/v4/tutorials/custom-valueaxis-grid-using-ranges/
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        var createGrid = util_1.createAxisGrid(valueAxis);
        var i = 0;
        for (; i <= this.props.maxValue; i += 5) {
            createGrid(i);
        }
        if (i !== this.props.maxValue) {
            createGrid(this.props.maxValue);
        }
        // always include zero in order to preserve differences
        valueAxis.min = 0;
        valueAxis.max = this.props.maxValue;
        valueAxis.strictMinMax = true;
    };
    MunicipalityBarChartFactory.prototype.addSeriesBullets = function (series) {
        if (series instanceof charts_1.ColumnSeries) {
            series.columns.template.column.cornerRadius(3, 3, 0, 0);
            var valueLabel = barChartFactory_1.BarChartFactory.addColumnSeriesLabelBullets(series, this.getSeriesValueXYName(), chartTheme_1.numberFormatter0Decimals);
            valueLabel.label.fontSize = 14;
            valueLabel.label.verticalCenter = "top";
            valueLabel.dy = 3;
            // valueLabel.locationY = 0.02;
        }
    };
    // Need to be empty to not add default legends.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    MunicipalityBarChartFactory.prototype.addLegend = function () { };
    return MunicipalityBarChartFactory;
}(abstractXYChartFactory_1.AbstractXYChartFactory));
exports.MunicipalityBarChartFactory = MunicipalityBarChartFactory;
