"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SKRComparison = void 0;
var React = __importStar(require("react"));
var _ = __importStar(require("lodash"));
var skr_1 = require("../../../fixtures/skr");
var allSKRChildren = _.flatMap(skr_1.SKR, function (value) { return value.children; });
var groupToMunicipalities = _.flatMap(allSKRChildren, function (value) { return _.map(value, function (value, group) { return [
    group,
    value.name,
    value.municipalities
]; }); });
var findFromMunicipality = function (municipality) {
    return _.find(groupToMunicipalities, function (_a) {
        var municipalities = _a[2];
        return _.includes(municipalities, municipality);
    });
};
exports.SKRComparison = React.memo(function (_a) {
    var active = _a.active, setActive = _a.setActive, activeMunicipality = _a.activeMunicipality, comparisonMunicipalitiesDispatch = _a.comparisonMunicipalitiesDispatch;
    var found = findFromMunicipality(activeMunicipality);
    var groupName;
    var comparisonMunicipalities;
    if (!found) {
        console.error("Couldn't find municipality " + activeMunicipality + " in any SKR group.");
    }
    else {
        var gName = found[1], municipalities = found[2];
        var comparison = typeof municipalities === 'string' ? [municipalities] : municipalities;
        comparisonMunicipalities = comparison;
        // const effective = _.filter(comparison, m => m !== activeMunicipality);
        // comparisonMunicipalities = effective;
        groupName = typeof gName === 'string' ? gName : gName.join(' ');
    }
    var onClick = function () {
        setActive('SKR');
        if (comparisonMunicipalities) {
            comparisonMunicipalitiesDispatch(['SET', comparisonMunicipalities]);
        }
    };
    // set municipalities once at initialization
    React.useEffect(function () {
        if (active === 'SKR' && comparisonMunicipalities) {
            comparisonMunicipalitiesDispatch(['SET', comparisonMunicipalities]);
        }
    });
    return (React.createElement("li", { className: active === 'SKR' ? 'active' : '' },
        React.createElement("a", { onMouseDown: onClick }, "SKR grupp \"" + groupName + "\"",
            React.createElement("i", { className: "fas fa-chevron-" + (active === 'SKR' ? "up" : "down") }))));
});
