"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toBarChartDataCombined = void 0;
var _ = __importStar(require("lodash"));
var toBarChartConverter_1 = require("./toBarChartConverter");
var seriesNameTo = function (seriesName, respondentType) { return seriesName + " (" + respondentType + ")"; };
var stripRespondentTypeFromTopTitle = function (topTitle) {
    var split = topTitle.split(/\(|\)/gm);
    var parenBody = split[1]
        .split(",")
        .map(function (e) { return e.trim(); })
        .filter(function (e) {
        // it's possible to use a type helper to be able to refer to the literal types as values, but...
        return e.toLowerCase() !== 'Företagare'.toLowerCase() &&
            e.toLowerCase() !== 'Politiker'.toLowerCase();
    })
        .join(", ")
        .trim();
    return split[0] + "(" + parenBody + ")";
};
function toBarChartDataCombined(_a, chartService) {
    var _b, _c;
    var chartDataEntrepreneurs = _a[0], chartDataPoliticians = _a[1];
    var barChartDataEntrepreneurs = toBarChartConverter_1.toBarChartData(chartDataEntrepreneurs, chartService);
    var barChartDataPoliticians = toBarChartConverter_1.toBarChartData(chartDataPoliticians, chartService);
    if (!_.isEqual(barChartDataEntrepreneurs.sources, barChartDataPoliticians.sources)) {
        console.error(barChartDataEntrepreneurs.sources);
        console.error(barChartDataPoliticians.sources);
        throw Error("Differing sources.");
    }
    if (!_.isEqual(barChartDataEntrepreneurs.title, barChartDataPoliticians.title))
        throw Error("Differing title.");
    if (!_.isEqual(barChartDataEntrepreneurs.year, barChartDataPoliticians.year))
        throw Error("Differing year.");
    if (barChartDataEntrepreneurs.category !== barChartDataPoliticians.category)
        throw Error("Differing categories.");
    var category = barChartDataEntrepreneurs.category;
    barChartDataEntrepreneurs.data = barChartDataEntrepreneurs.data.map(function (eData) {
        return _.mapKeys(eData, function (_, seriesName) { return seriesName !== category ? seriesNameTo(seriesName, 'Företagare') : seriesName; });
    });
    barChartDataPoliticians.data = barChartDataPoliticians.data.map(function (eData) {
        return _.mapKeys(eData, function (_, seriesName) { return seriesName !== category ? seriesNameTo(seriesName, 'Politiker') : seriesName; });
    });
    var mergedData = barChartDataEntrepreneurs.data.map(function (eData) {
        var pData = _.find(barChartDataPoliticians.data, function (series) { return series.category === eData.category; });
        return _.merge({}, eData, pData);
    });
    var mergedAttributes = _.chain(_.zip(barChartDataEntrepreneurs.seriesAttributes, barChartDataPoliticians.seriesAttributes))
        .flatMap(function (_a) {
        var entrepreneurs = _a[0], policitians = _a[1];
        var withUpdatedSeriesNameE = entrepreneurs
            ? __assign(__assign({}, entrepreneurs), { seriesName: seriesNameTo(entrepreneurs.seriesName, 'Företagare') }) : {};
        var withUpdatedSeriesNameP = policitians
            ? __assign(__assign({}, policitians), { seriesName: seriesNameTo(policitians.seriesName, 'Politiker') }) : {};
        return _.concat(withUpdatedSeriesNameE, withUpdatedSeriesNameP);
    })
        .value();
    var entrepreneursTT = stripRespondentTypeFromTopTitle((_b = barChartDataEntrepreneurs === null || barChartDataEntrepreneurs === void 0 ? void 0 : barChartDataEntrepreneurs.topTitle) !== null && _b !== void 0 ? _b : "");
    var politiciansTT = stripRespondentTypeFromTopTitle((_c = barChartDataPoliticians === null || barChartDataPoliticians === void 0 ? void 0 : barChartDataPoliticians.topTitle) !== null && _c !== void 0 ? _c : "");
    if (entrepreneursTT !== politiciansTT)
        throw Error("Differing TopTitle.");
    return {
        // props are the same
        sources: barChartDataEntrepreneurs.sources,
        title: barChartDataEntrepreneurs.title,
        year: barChartDataEntrepreneurs.year,
        category: barChartDataEntrepreneurs.category,
        // merged data
        data: mergedData,
        seriesAttributes: mergedAttributes,
        // topTitle is stripped from respondent type
        topTitle: entrepreneursTT
    };
}
exports.toBarChartDataCombined = toBarChartDataCombined;
