"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoComplete = exports.startsWithMatchFunction = exports.indexOfMatchFunction = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var _ = __importStar(require("lodash"));
var markSubstringMatches_1 = require("../lib/markSubstringMatches");
var MAX_SUGGESTIONS = 10;
exports.indexOfMatchFunction = function (filterString, alternative) { return alternative.indexOf(filterString) !== -1; };
exports.startsWithMatchFunction = function (filterString, alternative) { return alternative.startsWith(filterString); };
// Type is on the next line...
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
var getVisibleListFactory = function (isMatch) {
    return function (filterString, allAlternativesLower) {
        var nSuggestions = 0;
        return allAlternativesLower.map(function (alternative) {
            return !!(filterString &&
                isMatch(filterString, alternative) &&
                (nSuggestions++ < MAX_SUGGESTIONS));
        });
    };
};
exports.AutoComplete = function (props) {
    var getVisibleList = react_1.useState(function () { return getVisibleListFactory(props.isMatchFunction); })[0];
    var allAlternativesLower = props.allAlternatives.map(function (a) { return a.toLocaleLowerCase(); });
    var _a = React.useState(null), filterString = _a[0], setFilterString = _a[1];
    var _b = React.useState(-1), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var initialVisible = function () { return allAlternativesLower.map(function () { return false; }); };
    var _c = React.useState(initialVisible), visibleList = _c[0], setVisibleList = _c[1];
    var _d = React.useState(''), validInput = _d[0], setValidInput = _d[1];
    var reset = function () {
        setFilterString(null);
        setSelectedIndex(-1);
        setVisibleList(initialVisible);
    };
    var onKeyDown = function (e) {
        if (!filterString)
            return;
        switch (e.key) {
            case "ArrowDown": {
                e.preventDefault();
                var visibleList_1 = getVisibleList(filterString, allAlternativesLower);
                var fromIndex = selectedIndex !== -1 ? selectedIndex : 0;
                var nextVisibleIndex = _.findIndex(visibleList_1, function (visible, i) { return visible && i > selectedIndex; }, fromIndex);
                if (nextVisibleIndex !== -1) {
                    setSelectedIndex(nextVisibleIndex);
                }
                break;
            }
            case "ArrowUp": {
                e.preventDefault();
                var visibleList_2 = getVisibleList(filterString, allAlternativesLower);
                var fromIndex = selectedIndex !== -1 ? selectedIndex : visibleList_2.length - 1;
                var prevVisibleIndex = _.findLastIndex(visibleList_2, function (visible, i) { return visible && i < selectedIndex; }, fromIndex);
                if (prevVisibleIndex !== -1) {
                    setSelectedIndex(prevVisibleIndex);
                }
                break;
            }
            case "Enter": {
                e.preventDefault();
                if (selectedIndex !== -1) {
                    props.onSelect(props.allAlternatives[selectedIndex]);
                    reset();
                }
                else {
                    var indexOf = allAlternativesLower.indexOf(filterString);
                    if (indexOf !== -1) {
                        // exact match of an alternative
                        props.onSelect(props.allAlternatives[indexOf]);
                        reset();
                    }
                    else {
                        // nothing selected and input field does not contain a valid alternative
                        setValidInput('invalid');
                    }
                }
                break;
            }
            case "Escape": {
                reset();
            }
        }
    };
    var onChange = function (e) {
        var inputValue = e.target.value.trim();
        if ('' === inputValue) {
            // none visible
            reset();
        }
        else {
            // filter
            var filterString_1 = inputValue.toLocaleLowerCase();
            setFilterString(filterString_1);
            setValidInput('');
            setSelectedIndex(-1);
            setVisibleList(getVisibleList(filterString_1, allAlternativesLower));
        }
    };
    return React.createElement("div", { className: "autocomplete" },
        React.createElement("div", { className: "field" },
            React.createElement("i", { className: "fa fa-search" }),
            React.createElement("input", { className: validInput, placeholder: props.placeHolder, type: "text", value: filterString || '', onChange: onChange, onBlur: reset, onKeyDown: onKeyDown }),
            React.createElement(Suggestions, { filterString: filterString, allAlternatives: props.allAlternatives, selectedIndex: selectedIndex, visibleList: visibleList, onSelect: props.onSelect })));
};
var Suggestions = function (_a) {
    var filterString = _a.filterString, allAlternatives = _a.allAlternatives, selectedIndex = _a.selectedIndex, visibleList = _a.visibleList, onSelect = _a.onSelect;
    var suggestions = _.zip(allAlternatives, visibleList).map(function (_a, i) {
        var alternative = _a[0], visible = _a[1];
        return React.createElement("li", { key: i, className: "suggestions " + (visible ? '' : 'hidden') + " " + (i === selectedIndex ? 'selected' : ''), onMouseDown: alternative ? function () { return onSelect(alternative); } : undefined },
            React.createElement("a", { href: "#" }, visible
                ? alternative && React.createElement(markSubstringMatches_1.MarkSubstringMatches, { str: alternative, subStrings: filterString ? [filterString] : [] })
                : alternative));
    });
    return React.createElement("ul", { className: "suggestions " + (filterString !== null ? '' : 'hidden') }, suggestions);
};
