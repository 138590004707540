"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiChartLayoutHelper = void 0;
var abstractXYChartFactory_1 = require("./factories/abstractXYChartFactory");
var charts_1 = require("@amcharts/amcharts4/charts");
var core_1 = require("@amcharts/amcharts4/core");
var _ = __importStar(require("lodash"));
var chartTheme_1 = require("./chartTheme");
var MultiChartLayoutHelper = /** @class */ (function () {
    function MultiChartLayoutHelper(chartElemId, chartFactory) {
        this.chartElemId = chartElemId;
        this.chartFactory = chartFactory;
    }
    MultiChartLayoutHelper.prototype.createCharts = function (onReady) {
        var disposables = [];
        var mainContainer = core_1.create(this.chartElemId, core_1.Container);
        disposables.push(mainContainer);
        mainContainer.width = core_1.percent(100);
        mainContainer.height = core_1.percent(100);
        mainContainer.layout = "vertical";
        var titleLegendContainer = mainContainer.createChild(core_1.Container);
        disposables.push(titleLegendContainer);
        titleLegendContainer.width = core_1.percent(100);
        titleLegendContainer.layout = "horizontal";
        var label = titleLegendContainer.createChild(core_1.Label);
        disposables.push(label);
        label.text = "Guider - Översikt";
        abstractXYChartFactory_1.AbstractXYChartFactory.applyPrimaryTitleSettings(label);
        label.valign = "middle"; // vertical align with legend items
        // label.background.fill = color("green");
        var legend = titleLegendContainer.createChild(charts_1.Legend);
        disposables.push(legend);
        // invisible label used for centering the legend
        var alignmentLabel = titleLegendContainer.createChild(core_1.Label);
        disposables.push(alignmentLabel);
        alignmentLabel.text = label.text;
        alignmentLabel.fill = core_1.color("#f", 0);
        // legend.background.fill = color("blue");
        legend.contentAlign = "center";
        legend.position = "top";
        legend.labels.template.fill = chartTheme_1.PrimaryColor;
        // Main charts container to put the sub charts in
        var chartsContainer = mainContainer.createChild(core_1.Container);
        disposables.push(chartsContainer);
        chartsContainer.width = core_1.percent(100);
        chartsContainer.height = core_1.percent(100);
        chartsContainer.layout = "horizontal";
        var charts = this.chartFactory.map(function (factory) {
            var chart = factory.createChart(chartsContainer);
            disposables.push(chart);
            return chart;
        });
        this.setupLegendData(charts, legend);
        // Setup mechanism for synchronizing Axes titles alignment.
        var allAxes = charts.map(function (chart) { return chart.xAxes; });
        var categoryAxisTitleHeight = _.fill(new Array(allAxes.length), 0);
        charts.forEach(function (chart, index) {
            var alignAxisTitles = function (chart) {
                chart.xAxes.values.forEach(function (axis) {
                    // check all charts and set axis title height to the same for all
                    categoryAxisTitleHeight[index] = axis.title.measuredHeight;
                    var max = _.max(categoryAxisTitleHeight);
                    if (max && max !== axis.title.minHeight) {
                        allAxes.forEach(function (chartAxes) {
                            chartAxes.each(function (axis) {
                                axis.title.minHeight = max;
                            });
                        });
                    }
                    // make sure title keeps itself inside the axis
                    axis.title.maxWidth = axis.measuredWidth;
                });
            };
            // not perfect axis title container resizing, but better than nothing
            chart.events.on("sizechanged", function (e) {
                alignAxisTitles(e.target);
            });
        });
        // hide chart layout yanking around on layout creation
        mainContainer.hidden = true;
        mainContainer.events.on("ready", function () {
            mainContainer.show();
            onReady();
        });
        return disposables;
    };
    MultiChartLayoutHelper.prototype.setupLegendData = function (charts, legend) {
        // Pick the legend names from the series and assume that series are the same for all charts
        var chart0 = _.first(charts);
        if (chart0) {
            var legendData_1 = [];
            chart0.series.each(function (series) { return legendData_1.push({ name: series.name.toUpperCase(), fill: series.stroke }); });
            legend.data = legendData_1;
        }
    };
    return MultiChartLayoutHelper;
}());
exports.MultiChartLayoutHelper = MultiChartLayoutHelper;
