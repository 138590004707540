"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectionList = void 0;
var React = __importStar(require("react"));
var react_responsive_1 = require("react-responsive");
var _ = __importStar(require("lodash"));
var hooks_1 = require("./hooks");
var listGroup_1 = require("./listGroup");
var filterList_1 = require("../lib/filterList");
exports.SelectionList = function (props) {
    var setSelectedSlug0 = props.setSelectedSlug, nameFromSlug = props.nameFromSlug;
    var isMobile = react_responsive_1.useMediaQuery({ query: '(max-width: 767px)' });
    var _a = React.useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var setSelectedSlug = isMobile
        ? function (slug) { setIsOpen(false); setSelectedSlug0(slug); }
        : setSelectedSlug0;
    var _b = filterList_1.useFilterList([]), filterList = _b[0], onChange = _b[2];
    function getFilteredGroups() {
        var itemGroups = props.itemGroups, excludedItems = props.excludedItems;
        return _.mapValues(itemGroups, function (slugs) { return _.filter(slugs, function (f) { return !_.includes(excludedItems, f); }); });
    }
    var filteredGroups = getFilteredGroups();
    var groupElements = _.map(filteredGroups, function (slugs, groupName) {
        var listItemProps = _.map(slugs, function (slug) {
            var isSelected = props.selectedSlug === slug;
            return {
                slug: slug,
                isSelected: isSelected,
                isVisible: isSelected || filterList_1.namePassesFilter(nameFromSlug(slug), filterList),
                filterList: filterList,
                nameFromSlug: nameFromSlug,
                setSelectedSlug: setSelectedSlug
            };
        });
        return React.createElement(listGroup_1.ListGroup, { key: groupName, itemProps: listItemProps, groupName: groupName, filterList: filterList });
    });
    var inputRef = hooks_1.useFocusMnemonic("ctrl+shift+f");
    return (React.createElement(React.Fragment, null, !isMobile ? (React.createElement("div", { className: "factor-selection-container" },
        React.createElement("div", { className: "factor-selection-filter-container" },
            React.createElement("i", { className: "fa fa-search" }),
            React.createElement("input", { className: "factor-selection-filter-input", type: "text", placeholder: "Filtrera", onChange: onChange, ref: inputRef })),
        React.createElement("div", { className: "factor-selection-factor-list-container" }, groupElements))) : (React.createElement("div", { className: "municipality-select-comp" },
        React.createElement("div", { className: "municipality-select", onClick: function () { return setIsOpen(!isOpen); } },
            React.createElement("span", null, "V\u00E4lj faktor"),
            React.createElement("i", { className: "fas fa-chevron-" + (isOpen ? 'up' : 'down'), "aria-hidden": "true" })),
        React.createElement("div", { className: ['municipality-dropdown', isOpen ? 'open' : ''].join(' ') },
            React.createElement("div", { className: "factor-selection-factor-list-container" }, groupElements))))));
};
