"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.LineChartYearFactory = exports.Year = void 0;
var charts_1 = require("@amcharts/amcharts4/charts");
var abstractXYChartFactory_1 = require("./abstractXYChartFactory");
var chartTheme_1 = require("../chartTheme");
var core_1 = require("@amcharts/amcharts4/core");
exports.Year = 'year';
var LineChartYearFactory = /** @class */ (function (_super) {
    __extends(LineChartYearFactory, _super);
    function LineChartYearFactory(props) {
        return _super.call(this, props) || this;
    }
    LineChartYearFactory.prototype.configAxes = function (categoryAxis, valueAxis) {
        _super.prototype.configAxes.call(this, categoryAxis, valueAxis);
        // add a little bit of extra space to not truncate bullets etc when very close to min/max value
        valueAxis.extraMin = 0.05;
        valueAxis.extraMax = 0.05;
    };
    LineChartYearFactory.prototype.addSeriesBullets = function (series) {
        var bullet = series.bullets.push(new charts_1.CircleBullet());
        bullet.circle.radius = 5;
        bullet.circle.stroke = core_1.color(chartTheme_1.BackgroundColor);
        bullet.circle.strokeWidth = 2;
        this.addCustomBulletAppliedToLastYearDataPoint(series);
    };
    LineChartYearFactory.prototype.addCustomBulletAppliedToLastYearDataPoint = function (series) {
        var outerBullet = series.bullets.push(new charts_1.CircleBullet());
        outerBullet.circle.radius = 7;
        outerBullet.fillOpacity = 0.0;
        outerBullet.circle.strokeWidth = 2;
        outerBullet.circle.strokeOpacity = 0.0;
        // Add custom bullet for last datapoint
        // @See: https://www.amcharts.com/docs/v4/concepts/bullets/#Bullets_in_data
        outerBullet.circle.propertyFields.strokeOpacity = LineChartYearFactory.OUTER_BULLET_CIRCLE_OPACITY_FIELD_NAME;
    };
    LineChartYearFactory.prototype.seriesExportExclusions = function () {
        return [
            LineChartYearFactory.OUTER_BULLET_CIRCLE_OPACITY_FIELD_NAME,
            LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY,
            LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY_POLITICIAN
        ];
    };
    LineChartYearFactory.prototype.addSeriesModifications = function (series) {
        if (series.name.indexOf("Politiker") > -1) {
            // Hack for working around missing dashed line issue when there are gaps in series
            series.propertyFields.strokeDasharray = LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY_POLITICIAN;
        }
        else {
            series.propertyFields.strokeDasharray = LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY;
        }
    };
    LineChartYearFactory.prototype.defaultChartType = function () { return charts_1.LineSeries; };
    LineChartYearFactory.OUTER_BULLET_CIRCLE_OPACITY_FIELD_NAME = "outerBulletCircleOpacity";
    LineChartYearFactory.OUTER_BULLET_CIRCLE_OPACITY_VISIBLE_VALUE = 0.7;
    LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY = "lineSegmentDashArray";
    // Not happy about adding domain specific data here, but found no other way to pickup which series it is.
    LineChartYearFactory.LINE_SEGMENT_DASH_ARRAY_POLITICIAN = "lineSegmentDashArrayPolitician";
    return LineChartYearFactory;
}(abstractXYChartFactory_1.AbstractXYChartFactory));
exports.LineChartYearFactory = LineChartYearFactory;
