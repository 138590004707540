"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var autoComplete_1 = require("./listing/autoComplete");
var countyService_1 = require("../../services/countyService");
var counties_1 = require("../../fixtures/counties");
var HeaderMunicipalitySearch = function (props) {
    var allAlternatives = React.useState(function () { return new countyService_1.CountyService(counties_1.Counties).getAllRegions(); })[0];
    var onSelect = function (regionName) {
        window.location.href = props.redirectUrl + "?name=" + regionName;
    };
    return React.createElement("form", null,
        React.createElement(autoComplete_1.AutoComplete, { placeHolder: "Hitta din kommun", allAlternatives: allAlternatives, isMatchFunction: autoComplete_1.startsWithMatchFunction, onSelect: onSelect }));
};
// rails-react needs a default export in order to be able to find a component
// noinspection JSUnusedGlobalSymbols
exports.default = HeaderMunicipalitySearch;
