"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.customStyles = exports.isSelectOption = void 0;
function isSelectOption(option) {
    return !Array.isArray(option);
}
exports.isSelectOption = isSelectOption;
exports.customStyles = {
    dropdownIndicator: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isDisabled ? 'hsl(0,0%,60%)' : '#ef8201', width: '30px', marginTop: '5px' })); },
    option: function (provided, state) { return (__assign(__assign({}, provided), { color: state.isSelected ? '#33414e' : provided.color })); },
    indicatorSeparator: function () { return ({
        display: 'none'
    }); }
};
