"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MunicipalityOverviewChartWidget = void 0;
var React = __importStar(require("react"));
var factorService_1 = require("../../services/factorService");
var presentableDataClient_1 = require("../clients/presentableDataClient");
var factors_1 = require("../../fixtures/factors");
var factorChartComponent_1 = require("./charting/factorChartComponent");
var reactModalHelper_1 = require("./helpers/reactModalHelper");
var _ = __importStar(require("lodash"));
var chartFooter_1 = require("./charting/footer/chartFooter");
var footerSelect_1 = require("./charting/footer/footerSelect");
exports.MunicipalityOverviewChartWidget = function (props) {
    reactModalHelper_1.setReactModalAppElement();
    var factorSlug = props.initialFactorSlug;
    var factorService = React.useState(function () { return new factorService_1.FactorService(factors_1.Factors); })[0];
    var dataClientFactory = React.useState(function () { return presentableDataClient_1.presentableDataClientFactory(props.apiBaseUrl, factorService); })[0];
    var chartingLabels = factorService.getChartingLabels(factorSlug);
    var firstLabel = _.first(chartingLabels);
    var _a = React.useState(function () {
        return firstLabel
            ? factorService.getCharting(factorSlug, firstLabel)
            : undefined;
    }), factorCharting = _a[0], setFactorCharting = _a[1];
    var onFactorChartingChange = React.useCallback(function (selectedLabel) {
        setFactorCharting(factorService.getCharting(factorSlug, selectedLabel));
    }, [factorService, factorSlug]);
    var chartDataDataTransformer = factorService.getChartDataDataTransformer(factorService.getCharting(factorSlug, factorCharting === null || factorCharting === void 0 ? void 0 : factorCharting.label));
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: "chart-wrapper" },
            React.createElement(factorChartComponent_1.FactorChartComponent, { factorSlug: factorSlug, chartingLabel: factorCharting === null || factorCharting === void 0 ? void 0 : factorCharting.label, municipalities: _.isArray(props.initialMunicipality) ? props.initialMunicipality : [props.initialMunicipality], respondentType: undefined, dataClientFactory: dataClientFactory, chartDataDataTransformer: chartDataDataTransformer, factorService: factorService, width: 620, chartFooterComponent: React.createElement(chartFooter_1.ChartFooterComponent, { chartingSelectComponent: (factorCharting === null || factorCharting === void 0 ? void 0 : factorCharting.label) && React.createElement(footerSelect_1.FooterSelect, { disabled: _.isEmpty(chartingLabels), options: _.map(chartingLabels, function (l) { return ({ value: l, label: l }); }), selectedLabel: factorCharting === null || factorCharting === void 0 ? void 0 : factorCharting.label, onSelectedChange: onFactorChartingChange, menuPlacementTop: false }), menuPlacementTop: false }) })));
};
