"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.municipalityReducer = void 0;
var _ = __importStar(require("lodash"));
var func_1 = require("../util/func");
var counties_1 = require("../../fixtures/counties");
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var withAlwaysFirstAndUniq = function (alwaysFirst) { return function (updated) {
    if (_.includes(updated, alwaysFirst)) {
        updated = [alwaysFirst].concat(updated);
    }
    return _.uniq(updated);
}; };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
var withAlwaysLastAndUniq = function (alwaysLast) { return function (updated) {
    if (_.includes(updated, alwaysLast)) {
        updated = (Array()).concat(updated, [alwaysLast]);
    }
    return _.chain(updated).reverse().uniq().reverse().value();
}; };
var addUniqSort = function (prev, m, specialSortAndUniqFn, doSort) {
    if (doSort === void 0) { doSort = false; }
    var updated = doSort ? prev.concat(m).sort() : prev.concat(m);
    return specialSortAndUniqFn(updated);
};
var removeUniqSort = function (prev, m, specialSortAndUniqFn, doSort) {
    if (doSort === void 0) { doSort = false; }
    var stringArray = typeof m === 'string' ? [m] : m;
    var updated = doSort ? _.without.apply(_, __spreadArrays([prev], stringArray)).sort() : _.without.apply(_, __spreadArrays([prev], stringArray));
    return specialSortAndUniqFn(updated);
};
function first(m) {
    if (typeof m === 'string') {
        return m;
    }
    else {
        var first_1 = _.first(m);
        if (first_1 === undefined) {
            throw Error('No first in: ' + JSON.stringify(m));
        }
        else {
            return first_1;
        }
    }
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
exports.municipalityReducer = function (initialMunicipality, _tag) {
    if (_tag === void 0) { _tag = "default"; }
    return function (state, _a) {
        var type = _a[0], municipalities = _a[1], municipalitiesOpt = _a[2];
        var equalState = municipalitiesOpt
            ? _.isEqual(state, municipalities) && _.isEqual(state, municipalitiesOpt)
            : _.isEqual(state, municipalities);
        if (equalState)
            return state;
        // if Sweden was actually set as the first municipality we don't want to sort it last
        var firstInitial = first(initialMunicipality);
        var specialSortAndUniqFn = firstInitial === counties_1.Sweden ? _.uniq : withAlwaysLastAndUniq(counties_1.Sweden);
        var resultingMunicipalities = [];
        switch (type) {
            case "ADD": {
                resultingMunicipalities = addUniqSort(state, municipalities, specialSortAndUniqFn);
                break;
            }
            case "REMOVE": {
                resultingMunicipalities = removeUniqSort(state, municipalities, specialSortAndUniqFn);
                break;
            }
            case "REMOVE_AND_ADD": {
                func_1.assertIsDefined(municipalitiesOpt);
                var afterRemove = removeUniqSort(state, municipalities, specialSortAndUniqFn);
                resultingMunicipalities = addUniqSort(afterRemove, municipalitiesOpt, specialSortAndUniqFn);
                break;
            }
            case "SET": {
                resultingMunicipalities = municipalities;
                break;
            }
        }
        return resultingMunicipalities;
    };
};
