"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FactorChartComponent = void 0;
var React = __importStar(require("react"));
var react_1 = require("react");
var lineChartYearFactory_1 = require("./factories/lineChartYearFactory");
var toLineChartYearConverter_1 = require("./toLineChartYearConverter");
var barChartFactory_1 = require("./factories/barChartFactory");
var toBarChartConverter_1 = require("./toBarChartConverter");
var _ = __importStar(require("lodash"));
var XYChartComponent_1 = require("./XYChartComponent");
var invertedRankingChartFactory_1 = require("./factories/invertedRankingChartFactory");
var toBarChartCombinedConverter_1 = require("./toBarChartCombinedConverter");
var getTopTitle = function (props, chartProps, extraParenthesizedItems) {
    var sourceTranslated = props.factorService.getSourceTranslated(props.factorSlug);
    var units = chartProps === null || chartProps === void 0 ? void 0 : chartProps.seriesAttributes.map(function (sa) { return sa.seriesUnit; });
    var uniqueUnits = _.uniq(units);
    var parenthesisData = _.filter(__spreadArrays([
        props.respondentType
    ], uniqueUnits, (extraParenthesizedItems !== null && extraParenthesizedItems !== void 0 ? extraParenthesizedItems : [])), function (elem) {
        return !_.isEmpty(elem) &&
            (elem === null || elem === void 0 ? void 0 : elem.toLowerCase()) !== "svarssnitt";
    } // ugly hack for removing unwanted "Svarssnitt" unit in title
    );
    return !_.isEmpty(parenthesisData)
        ? sourceTranslated + " (" + parenthesisData.join(", ") + ")"
        : "" + sourceTranslated;
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
var getSources = function (sources, sourceFilter) {
    if (sourceFilter === void 0) { sourceFilter = function () { return true; }; }
    var sourcesList;
    if (sources) {
        var uniqSources = _.chain(sources).uniq().filter(function (s) { return !_.isEmpty(s); }).value();
        var splitSources = _.flatMap(uniqSources, function (us) { return us.split(","); });
        sourcesList = _.filter(splitSources, sourceFilter);
    }
    else {
        sourcesList = undefined;
    }
    return sourcesList !== undefined && !_.isEmpty(sourcesList) ? "K\u00E4lla: " + sourcesList.join(", ") : undefined;
};
/* During the time between the survey release and the ranking release, the survey ranking factors should not be included
* in the data which is exported as XLSX directly from the charts. Need to be updated before ranking release. */
var forbiddenExportYear = '2024';
var latestYearSurveyFactorExportDataFilterCreator = function (factorService, factorSlug) {
    if (factorService.isRankingFactor(factorSlug) && factorService.getSource(factorSlug)) {
        return function (data) {
            var filteredData = data.data.filter(function (item) {
                var year = item.year;
                return year != forbiddenExportYear;
            });
            return { data: filteredData };
        };
    }
    else {
        return undefined;
    }
};
// Ugly hack for making sure the result of the most recently issued api call doesn't get overwritten by a slower request
// issued earlier. Whole issue originates from the includeSwedenByDefault() functionality and causes multiple API calls.
// (Switching between Nyföretagsamhet and Arbetsställen triggers it around half of the time.)
var uglyRequestIndexHackCounter = 0;
var MunicipalityFactorValues = function (props) {
    var dataClientFactory = props.dataClientFactory, factorSlug = props.factorSlug, chartingLabel = props.chartingLabel, municipalities = props.municipalities, respondentType = props.respondentType, factorService = props.factorService;
    var _a = react_1.useState(), lineChartYearFactoryProps = _a[0], setLineChartYearFactoryProps = _a[1];
    React.useEffect(function () {
        // Uncomment to notice the double api requests
        // console.log("MUNICIPALITIES:")
        // console.log(municipalities);
        var requestIndex = ++uglyRequestIndexHackCounter;
        dataClientFactory(factorSlug, chartingLabel).getMFVData({ municipalities: municipalities, respondentType: respondentType })
            .then(function (chartData) {
            if (requestIndex !== uglyRequestIndexHackCounter) {
                return;
            }
            if (props.chartDataDataTransformer) {
                chartData = props.chartDataDataTransformer(chartData);
            }
            var chartConfig = factorService.getChartConfig(factorSlug, chartingLabel);
            var lcyData = toLineChartYearConverter_1.toLineChartYearData(chartData, chartConfig);
            var sources = getSources(_.values(lcyData.sources), chartConfig.sourceExcludeFilter);
            setLineChartYearFactoryProps(__assign(__assign({}, lcyData), { fullScreenButtonHandler: props.fullScreenButtonHandler, showSNLogo: props.showSNLogo, exportMenuElemId: props.exportMenuElemId, exportDataFilter: latestYearSurveyFactorExportDataFilterCreator(factorService, factorSlug), topTitle: getTopTitle(props, lcyData, [chartConfig.unit, sources]), subtitle: factorService.getFactorDescription(factorSlug), scaleDescription: factorService.getScaleDescription(factorSlug, chartingLabel), seriesIsIntegerData: chartConfig.seriesIsIntegerData }));
        });
    }, [props, factorSlug, chartingLabel, municipalities, respondentType, dataClientFactory, factorService]);
    if (!lineChartYearFactoryProps)
        return null;
    var ChartFactory = factorService.getChartConfig(factorSlug, chartingLabel).isRankingChart
        ? invertedRankingChartFactory_1.InvertedRankingChartFactory
        : lineChartYearFactory_1.LineChartYearFactory;
    var chartFactory = new ChartFactory(lineChartYearFactoryProps);
    return React.createElement(XYChartComponent_1.XYChartComponent, { chartFactory: chartFactory, width: props.width, height: props.height, chartFooterComponent: props.chartFooterComponent });
};
function useSurveyFactorBothLineChart(getSFBData, props) {
    var dataClientFactory = props.dataClientFactory, factorSlug = props.factorSlug, municipalities = props.municipalities, respondentType = props.respondentType, factorService = props.factorService, chartingLabel = props.chartingLabel;
    var _a = React.useState(), lineChartYearFactoryProps = _a[0], setLineChartYearFactoryProps = _a[1];
    React.useEffect(function () {
        var requestIndex = ++uglyRequestIndexHackCounter;
        getSFBData()
            .then(function (chartData) {
            if (requestIndex !== uglyRequestIndexHackCounter) {
                return;
            }
            if (props.chartDataDataTransformer) {
                chartData = props.chartDataDataTransformer(chartData);
            }
            var chartConfig = factorService.getChartConfig(factorSlug, chartingLabel);
            var lcyData = toLineChartYearConverter_1.toLineChartYearData(chartData, chartConfig);
            var sources = getSources(_.values(lcyData.sources), chartConfig.sourceExcludeFilter);
            setLineChartYearFactoryProps(__assign(__assign({}, lcyData), { fullScreenButtonHandler: props.fullScreenButtonHandler, showSNLogo: props.showSNLogo, exportMenuElemId: props.exportMenuElemId, exportDataFilter: latestYearSurveyFactorExportDataFilterCreator(factorService, factorSlug), topTitle: getTopTitle(props, lcyData, __spreadArrays(municipalities, [chartConfig.unit, sources])), subtitle: factorService.getFactorDescription(factorSlug), scaleDescription: factorService.getScaleDescription(factorSlug, chartingLabel), seriesIsIntegerData: chartConfig.seriesIsIntegerData }));
        });
    }, [props, factorSlug, municipalities, respondentType, dataClientFactory, factorService, getSFBData, chartingLabel]);
    if (!lineChartYearFactoryProps)
        return null;
    var chartFactory = new lineChartYearFactory_1.LineChartYearFactory(lineChartYearFactoryProps);
    return React.createElement(XYChartComponent_1.XYChartComponent, { chartFactory: chartFactory, width: props.width, height: props.height, chartFooterComponent: props.chartFooterComponent });
}
var SurveyFactorBothLineChart = function (props) {
    return useSurveyFactorBothLineChart(React.useCallback(function () { return props.dataClientFactory(props.factorSlug, props.chartingLabel).getSFBData(props); }, [props]), props);
};
var SurveyFactorBothMultiLineChart = function (props) {
    return useSurveyFactorBothLineChart(React.useCallback(function () { return props.dataClientFactory(props.factorSlug, props.chartingLabel).getSFBMData(props); }, [props]), props);
};
function useSurveySimpleBarchart(transformer, props) {
    var _a;
    var factorSlug = props.factorSlug, factorService = props.factorService, chartingLabel = props.chartingLabel;
    var _b = React.useState(), barChartFactoryProps = _b[0], setBarChartFactoryProps = _b[1];
    React.useEffect(function () {
        var requestIndex = ++uglyRequestIndexHackCounter;
        transformer().then(function (props) {
            if (requestIndex !== uglyRequestIndexHackCounter) {
                return;
            }
            setBarChartFactoryProps(props);
        });
    }, [transformer, setBarChartFactoryProps]);
    if (!barChartFactoryProps)
        return null;
    var chartConfig = factorService.getChartConfig(factorSlug, chartingLabel);
    var sources = getSources(_.values(barChartFactoryProps.sources), chartConfig.sourceExcludeFilter);
    var chartFactory = new barChartFactory_1.BarChartFactory(__assign(__assign({}, barChartFactoryProps), { fullScreenButtonHandler: props.fullScreenButtonHandler, showSNLogo: props.showSNLogo, exportMenuElemId: props.exportMenuElemId, exportDataFilter: latestYearSurveyFactorExportDataFilterCreator(factorService, factorSlug), topTitle: getTopTitle(props, barChartFactoryProps, [barChartFactoryProps.year, chartConfig.unit, sources]), subtitle: factorService.getFactorDescription(factorSlug), scaleDescription: factorService.getScaleDescription(factorSlug, chartingLabel), extraLabelWidth: chartConfig === null || chartConfig === void 0 ? void 0 : chartConfig.extraLabelWidth, truncate: (_a = factorService.getChartConfig(factorSlug)) === null || _a === void 0 ? void 0 : _a.truncate, seriesIsIntegerData: chartConfig.seriesIsIntegerData }));
    return React.createElement(XYChartComponent_1.XYChartComponent, { chartFactory: chartFactory, width: props.width, height: props.height, chartFooterComponent: props.chartFooterComponent });
}
var SurveySimpleBarchartCombined = function (props) {
    var dataClientFactory = props.dataClientFactory, factorSlug = props.factorSlug, municipalities = props.municipalities, respondentType = props.respondentType, factorService = props.factorService, dataEndpoint = props.dataEndpoint, chartingLabel = props.chartingLabel;
    var propsWithoutRespondentTypes = __assign(__assign({}, props), { respondentType: undefined });
    var callback = React.useCallback(function () {
        return dataClientFactory(factorSlug, chartingLabel)
            .getBarChartDataCombinedRespondents({ municipalities: municipalities, respondentType: respondentType }, dataEndpoint !== null && dataEndpoint !== void 0 ? dataEndpoint : 'SurveySimpleBarchart')
            .then(function (chartData) { return toBarChartCombinedConverter_1.toBarChartDataCombined(chartData, factorService.getChartConfig(factorSlug, chartingLabel)); });
    }, [dataClientFactory, factorSlug, chartingLabel, municipalities, respondentType, dataEndpoint, factorService]);
    return useSurveySimpleBarchart(callback, propsWithoutRespondentTypes);
};
var SurveySimpleBarchart = function (props) {
    var dataClientFactory = props.dataClientFactory, municipalities = props.municipalities, respondentType = props.respondentType, factorService = props.factorService, factorSlug = props.factorSlug, chartingLabel = props.chartingLabel, dataEndpoint = props.dataEndpoint;
    var callback = React.useCallback(function () {
        return dataClientFactory(factorSlug, chartingLabel).getBarChartData({ municipalities: municipalities, respondentType: respondentType }, dataEndpoint !== null && dataEndpoint !== void 0 ? dataEndpoint : 'SurveySimpleBarchart')
            .then(function (chartData) { return toBarChartConverter_1.toBarChartData(chartData, factorService.getChartConfig(factorSlug, chartingLabel)); });
    }, [dataClientFactory, factorSlug, chartingLabel, municipalities, respondentType, dataEndpoint, factorService]);
    return useSurveySimpleBarchart(callback, props);
};
var InDepthSurveyQuestions = function (props) {
    return React.createElement(SurveySimpleBarchart, __assign({}, props, { dataEndpoint: 'InDepthSurveyQuestions' }));
};
exports.FactorChartComponent = function (props) {
    if (_.isEmpty(props.municipalities))
        return null;
    var dataEndpoint = props.dataEndpoint, factorSlug = props.factorSlug, factorService = props.factorService, chartingLabel = props.chartingLabel;
    // chart type selector
    switch (dataEndpoint !== null && dataEndpoint !== void 0 ? dataEndpoint : factorService.getDataEndpoint(factorSlug, chartingLabel)) {
        case 'MunicipalityFactorValues':
            return React.createElement(MunicipalityFactorValues, __assign({}, props));
        case 'SurveyFactorBoth':
            return React.createElement(SurveyFactorBothLineChart, __assign({}, props));
        case 'SurveyFactorBothMulti':
            return React.createElement(SurveyFactorBothMultiLineChart, __assign({}, props));
        case 'SurveySimpleBarchart':
            return React.createElement(SurveySimpleBarchart, __assign({}, props));
        case 'SurveySimpleBarchartCombined':
            return React.createElement(SurveySimpleBarchartCombined, __assign({}, props));
        case 'InDepthSurveyQuestions':
            return React.createElement(InDepthSurveyQuestions, __assign({}, props));
    }
};
