"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Guides = exports.ChecklistGuidesEntries = exports.GuideSlug = exports.ChecklistGuideSlug = exports.NonChecklistGuideSlug = exports.VimeoGuideSlug = void 0;
var func_1 = require("../react/util/func");
exports.VimeoGuideSlug = func_1.StringUnion('guide_to_lead_towards_a_better_business_climate', 'guide_training_for_better_service', 'guide_decreased_crime_and_increased_safety', 'guide_more_effective_and_attractive_procurements', 'guide_yrkesvux_for_increased_inclusivity_and_growing_businesses');
exports.NonChecklistGuideSlug = func_1.StringUnion('guide_competence_supply', 'guide_systematic_improvement_work', 'guide_together_against_crime_and_unsafety');
exports.ChecklistGuideSlug = func_1.StringUnion('guide_competition_on_equal_terms', 'guide_goals_strategy_and_leadership', 'guide_procurement_as_a_strategic_tool', 'guide_service_directed_oversight');
exports.GuideSlug = func_1.StringUnion.apply(void 0, __spreadArrays(exports.VimeoGuideSlug.values, exports.NonChecklistGuideSlug.values, exports.ChecklistGuideSlug.values));
var VimeoGuidesEntries = {
    "guide_to_lead_towards_a_better_business_climate": {
        order: 100,
        title: "Att leda mot ett bättre företagsklimat",
        description: ["Vad krävs av den politiska ledningen och högsta tjänstemannaledningen för att åstadkomma verklig förbättring av företagsklimatet? I samtal med några av landets framgångsrikaste kommuner undersöker vi vad som har krävts för att föra deras kommuner till toppen."],
        vimeoId: "643948048",
        videoCredits: "Per Vorberg, KSO i Härryda kommun, Mikael Peterson, KSO i Kungsör kommun, Peter Schölander, KSO i Höganäs kommun och Claes Nordmark, KSO i Bodens kommun"
    },
    'guide_training_for_better_service': {
        order: 110,
        title: "Träning för bättre service",
        description: ["Världens bästa idrottare tränar hårt och målmedvetet. När Armand Duplantis tog OS-guld i Tokyo fick han feedback från coachen efter varje hopp för att inte missa några detaljer. Även servicen och kvalitet i kommunens myndighetsutövande kan utvecklas och förbättras av kontinuerlig träning och feedback. Vid detta seminarium diskuteras hur träning kan ge bättre service i kommunal myndighetsutövning och några spännande exempel presenteras."],
        vimeoId: "645999893",
        videoCredits: "Maria Söderlund, Miljö- och byggchef i Sollefteå, Maria Faltin, Näringslivschef i Nybro, Sanna Tardell, Business manager på Transcom och Madeleine Nemstrand, Näringslivsutvecklare i Falkenberg"
    },
    'guide_decreased_crime_and_increased_safety': {
        order: 120,
        title: "Minskad brottslighet och ökad trygghet – att involvera näringslivet",
        description: [
            "Att skydda sig mot brott och minska personalens känsla av otrygghet har blivit vardag för de allra flesta företag. Tid och pengar läggs på säkerhetsåtgärder som annars skulle använts för att utveckla företagets verksamhet. Tillväxt, innovationer och nyanställningar får stå tillbaka för den ökande kriminaliteten.",
            "Tillsammans med näringslivet kan kommunerna utveckla handlingsplaner för proaktiva insatser. Detta är ett arbete som inletts i flera kommuner. Under inspirationstimmen lyfter vi flera goda exempel och reflekterar kring kommuners och näringslivets samverkan för ett samhälle med ökad trygghet och minskad brottslighet."
        ],
        vimeoId: "646408589",
        videoCredits: "Henrik Andershed, Professor i psykologi och kriminologi, forskningsledarna vid CAPS vid Örebro universitet, Jan Landström, Kriminolog och Säkerhetssamordnare i Nacka kommun och Ann Rönnäng, Regional brottsförebyggande samordnare hos Polisen i Region Väst"
    },
    "guide_more_effective_and_attractive_procurements": {
        order: 130,
        title: "Effektivare och attraktivare upphandlingar",
        description: ["En stor del av kommunens verksamhet köps in från externa leverantörer. Kommunen vill ha en effektiv upphandling som ger hög kvalitet till ett fördelaktigt pris. För att uppnå detta behöver upphandlingarna uppfattas som attraktiva av potentiella leverantörer. Kategoristyrning är nyckeln till att åstadkomma detta. Vi tittar närmare på kommuner som har lyckats och implementeringsexperternas bästa lärdomar för att komma igång."],
        vimeoId: "646851764",
        videoCredits: "Sebastian Nordgren, Inköpschef i Nacka, Ellen Hausel Heldahl, Expert upphandling Svenskt Näringsliv och Johan Johansson, upphandlingschef i Alvesta."
    },
    'guide_yrkesvux_for_increased_inclusivity_and_growing_businesses': {
        order: 140,
        title: "Yrkesvux för minskat utanförskap och växande företag",
        description: ["Tillgång till kompetens har tillsammans med upphandling varit den fråga som oftast fått sämst betyg när Svenskt Näringsliv varje åter mäter företagsklimatet i Sveriges kommuner. Mellan vart fjärde och vart femte rekryteringsförsök misslyckas. Framför allt råder brist på personer med gymnasial yrkesutbildning. Under dagens webinarium tar vi del av kommuner som lyckats bygga ut sin vuxenutbildning för att möta behoven och få fler i arbete."],
        vimeoId: "647279813",
        videoCredits: "Simon Dahlgren, verksamhetschef för vuxenutbildningen i Skellefteå kommun och Mats Berglund, sektionschef för kompetensförsörjning i Falu kommun"
    },
};
var NonChecklistGuidesEntries = {
    "guide_competence_supply": {
        order: 15,
        title: "Kompetensförsörjning",
        description: "En av företagens allra största utmaningar är att kunna rekrytera nya medarbetare. I den här guiden tittar vi närmare på dialog skola/näringsliv, dimensionering, studie- och yrkesvägledning och mycket annat där kommunen kan göra verklig skillnad.",
        thumbUrl: "guide_competence_supply.jpg",
        downloadUrl: "/files/guides/Guide kompetensförsörjning 2023.pdf"
    },
    "guide_systematic_improvement_work": {
        order: 13,
        title: "Systematiskt förbättringsarbete",
        description: "Arbetet med företagsklimatet vinner på att bedrivas som ett systematiskt förbättringsarbete. I den här guiden tittare vi närmare på hur man kan arbeta med analys, handlingsplaner och uppföljning.",
        thumbUrl: "guide_systematic_improvement_work.jpg",
        downloadUrl: "/files/guides/Guide systematiskt förbättringsarbete.pdf"
    },
    "guide_together_against_crime_and_unsafety": {
        order: 400,
        title: "Tillsammans med näringslivet mot brott och otrygghet",
        description: "Brottsligheten har kommit att bli ett av de största hoten mot företagsamheten i Sverige. Svenska företag är överens om att minskad brottslighet och ökad trygghet är en av de mest prioriterade frågorna för att förbättra det lokala företagsklimatet. Tillsammans med Brottsförebyggande rådet - Brå, Polisen och Länsstyrelserna i Sverige har vi på Svenskt Näringsliv därför tagit fram en vägledande guide med fokus på näringslivet och dess viktiga roll i brottsförebyggande arbete. Vår förhoppning är att guiden genom sina goda råd, konkreta verktyg och praktiska exempel skall inspirera till ett förstärkt brottsförebyggande arbete där näringslivet är en självklar del.",
        thumbUrl: "guide_tillsammans.png",
        downloadUrl: "/files/guides/guide_tillsammans_med_naringslivet_mot_brott_och_otrygghet.pdf",
    }
};
exports.ChecklistGuidesEntries = {
    "guide_competition_on_equal_terms": {
        order: 20,
        title: "(O)sund konkurrens",
        description: "När en kommun utsätter företag för osund konkurrens riskerar det leda både till ett sämre företagsklimat och ineffektiva metoder. I den här guiden tittar vi närmare på hur kommuner kan arbeta med förhållningssätt och policies för att gynna en sund konkurrenssituation.",
        thumbUrl: "guide_osund.png",
        checklistUrl: "guide_konkurrens_checklist.pdf",
        downloadUrl: "/files/guides/sn-guide-osund-konkurrens-web.pdf",
        checkListTitle: "Checklista för sund konkurrens",
        sections: [
            {
                title: "Checklista för sund konkurrens",
                items: [
                    { title: "Kommunen har en policy för kommunens säljverksamhet." },
                    { title: "All kommunal säljverksamhet är inventerad." },
                    { title: "Säljverksamhet som inte lever upp till 3 L-metoden har åtgärdats eller är på väg att åtgärdas." },
                    { title: "Det förs en årlig dialog med näringslivet om konkurrenssituationen." },
                    { title: "Kommunen har en tidig dialog med näringslivet inför ny eller utökad säljverksamhet." },
                    { title: "Det finns en rutin för hur klagomål eller anmälningar från företag ska tas tillvara." }
                ]
            }
        ]
    },
    "guide_goals_strategy_and_leadership": {
        order: 10,
        title: "Mål, strategi och ledarskap",
        description: "Ett förbättrat företagsklimat börjar nästan alltid med ledarskapet. I den här guiden tittar vi närmare på hur kommuner kan arbeta med mål, styrning, ledarskap och dialog med det lokala näringslivet.",
        thumbUrl: "guide_mal.png",
        checklistUrl: "guide_mal_strategi_sedarskap_checklist.pdf",
        downloadUrl: "/files/guides/sn-guide-mal-strategi-ledarskap-web.pdf",
        checkListTitle: "Checklista för mål, strategi och ledarskap",
        sections: [
            {
                title: "Tydligt ledarskap",
                items: [
                    { title: "Den politiska ledningen och tjänstemannaledningen har en tydlig och gemensam målbild för arbetet med företagsklimatet." },
                    { title: "Högsta politikerna och cheferna tar på sig ledarskapet i näringspolitiken och kommunicerar frågorna tydligt både internt och externt." },
                    { title: "Kommunledningen arbetar långsiktigt med frågorna för att skapa en varaktig organisationskultur." },
                    { title: "Näringslivsfrågorna får stort utrymme på kommunstyrelsens och tjänstemannaledningens agendor." },
                ]
            },
            {
                title: "Tydliga mål",
                items: [
                    { title: "Kommunens mål är mätbart, realistiskt och långsiktigt." },
                    { title: "Kommunens mål genomsyrar hela organisationens arbete." },
                    { title: "Kommunens mål följs upp regelbundet." },
                    { title: "Målet är framtaget i dialog med näringslivet." },
                ]
            },
            {
                title: "Inkluderande strategiarbete",
                items: [
                    { title: "Näringslivet involveras i strategiarbetet, så att deras behov och vardag får styra strategin." },
                    { title: "Strategin fokuserar på åtgärder som ger konkret nytta för företagen." },
                    { title: "Varje verksamhet har analyserat på vilket sätt de kommer i kontakt med näringslivet och hur de har en påverkan på företagsklimatet." },
                    { title: "Kommunens övergripande mål har brutits ner i specifika mål, aktiviteter och metoder för varje förvaltning och enhet." },
                    { title: "Resultaten i utvecklingsarbetet kommuniceras till näringslivet, så att de ser vad som händer." },
                ]
            },
            {
                title: "Dialog med näringslivet",
                items: [
                    { title: "Kommunstyrelsens ordförande reserverar en halvdag i veckan för företagsbesök." },
                    { title: "Företagen bjuds regelbundet in till frukostmöten, lunchmöten eller andra aktiviteter." },
                    { title: "Kommunen har gått igenom hemsidans struktur och innehåll utifrån en företagares perspektiv." },
                    { title: "Kommunen har ett nyhetsbrev som skickas ut regelbundet." },
                ]
            },
            {
                title: "Handlingskraftig näringslivsorganisation",
                items: [
                    { title: "Näringslivschefen ingår i ledningsgruppen och får stort utrymme för sina frågor." },
                    { title: "Näringslivschefen har ett starkt mandat att driva utvecklingsfrågor för hela kommunen." },
                    { title: "Näringslivsfunktionen arbetar nära kommunens andra förvaltningar." },
                ]
            }
        ]
    },
    "guide_service_directed_oversight": {
        order: 14,
        title: "Serviceinriktad myndighetsutövning",
        description: "Genom att arbeta serviceinriktat i myndighetsutövningen kan kommunen både öka regelefterlevnaden och förbättra företagsklimatet. I den här guiden tittar vi närmare på kommunen kan utveckla sin tillstånds- och tillsynsverksamhet, hjälpa företagen att göra rätt från början och bygga förtroende hos företagen.",
        thumbUrl: "guide_serviceinriktad_myndighetsutovning.png",
        checklistUrl: "guide_serviceinriktad_myndighetsutovning_checklist.pdf",
        downloadUrl: "/files/guides/Guide Serviceinriktad myndighetsutövning 2024 web.pdf",
        checkListTitle: "Checklista för serviceinriktad myndighetsutövning",
        sections: []
    },
    "guide_procurement_as_a_strategic_tool": {
        order: 17,
        title: "Upphandling",
        description: "Genom att arbeta bättre med upphandlingar kan kommuner både åstadkomma större nytta för medborgarna och ett bättre företagsklimat. I den här guiden tittar vi närmare på hur kommunen kan ställa mer träffsäkra krav, öka sin kostnadskontroll, underlätta för mindre företag att lägga anbud och mycket annat.",
        thumbUrl: "guide_upphandling.png",
        checklistUrl: "guide_upphandling_checklist.pdf",
        downloadUrl: "/files/guides/Guide Upphandling 2023 Webb.pdf",
        checkListTitle: "Checklista för smartare upphandlingar",
        sections: [
            {
                title: "Inköpsmogen organisation",
                items: [
                    { title: "Vi har mätt vår inköpsmognad." },
                    { title: "Vi har inrättat tvärfunktionella team som arbetar med kategoristyrning." },
                    { title: "Upphandlingschefen sitter i ledningsgruppen." },
                    { title: "Vi har tagit fram nya policyer och rutiner." },
                    { title: "Vi arbetar med att förbättra hela kommunkoncernens upphandlingar." },
                ]
            },
            {
                title: "Få koll på era köp",
                items: [
                    { title: "Vi har införskaffat ett spendverktyg, så att vi vet vart pengarna går och var vi kan effektivisera." },
                    { title: "Vi godkänner inga kvittoredovisningar." },
                    { title: "Alla använder upphandlade leverantörer." },
                    { title: "Vi har inrättat upphandlingskörkort, som krävs för att få göra avrop." },
                ]
            },
            {
                title: "Smartare kravställning",
                items: [
                    { title: "Vi utvärderar avtalsperioden och för en dialog med leverantören inför en ny upphandling." },
                    { title: "Vi analyserar verksamhetens faktiska behov inför kravställningen." },
                    { title: "Vi har dialog med marknaden inför upphandlingen." },
                    { title: "Vi använder funktionskrav." },
                    { title: "Vi har rensat bort alla ska-krav som inte är direkt kopplade till den funktion vi vill ha." },
                    { title: "Bara den bästa anbudsgivaren behöver inkomma med all dokumentation." },
                    { title: "Vi har förenklat språket i upphandlingsdokumenten." },
                    { title: "Krav inom miljö och social hållbarhet använder vi bara när de är relevanta för den specifika verksamheten och har känd effekt." },
                ]
            },
            {
                title: "Öppna upp för fler, mindre och lokala företag",
                items: [
                    { title: "Vi bjuder in lokala företag till upphandlingsskola." },
                    { title: "Vi bjuder in lokala företag till upphandlingsträffar om kommande upphandlingar." },
                    { title: "Vi publicerar alla avtal på hemsidan." },
                    { title: "Vi annonserar ut alla kommande upphandlingar i god tid på hemsidan." },
                    { title: "Vi delar upp stora upphandlingar i mindre delar." },
                    { title: "Företagen får tillräcklig tid att lägga anbud." },
                    { title: "Alla anbudsgivare får ett samtal om utfallet och varför de inte vann upphandlingen." },
                    { title: "Vi följer upp varför företag avstår från att lägga anbud." },
                ]
            },
            {
                title: "Bättre uppföljning",
                items: [
                    { title: "Förvaltningarna har avsatt resurser till avtalsuppföljning." },
                    { title: "Kommunen har en avtalscontroller." },
                    { title: "Krav som inte finns i uppföljningsplaner har vi tagit bort från upphandlingdokumenten." },
                ]
            }
        ]
    }
};
exports.Guides = __assign(__assign(__assign({}, VimeoGuidesEntries), NonChecklistGuidesEntries), exports.ChecklistGuidesEntries);
