"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createAxisGrid = void 0;
exports.createAxisGrid = function (valueAxis) { return function (value) {
    if (valueAxis.axisRanges.values.findIndex(function (range) { return range.value === value; }) === -1) {
        var range = valueAxis.axisRanges.create();
        range.value = value;
        range.label.text = "{value}";
    }
}; };
