"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Grouping = void 0;
exports.Grouping = {
    "survey": [
        {
            "name": "Företagsklimatet",
            "factors": [
                "summary_evaluation",
                "summary_evaluation_sweden",
                "most_prioritized_actions"
            ]
        },
        {
            "name": "Kontakter med kommunen",
            "factors": [
                "muni_contact_latest_year",
                "muni_contact_latest_year_about"
            ]
        },
        {
            "name": "Attityder till företagande",
            "factors": [
                "attitudes_politicians",
                "attitudes_civil_servants",
                "attitudes_public",
                "attitudes_schools"
            ]
        },
        {
            "name": "Kommunens myndighetsutövning",
            "factors": [
                "services_to_businesses",
                "municipal_contracting",
                "competition_with_private_companies",
                "survey_contact_understanding",
                "survey_contact_handling",
                "survey_contact_reachable",
                "advice_on_permission"
            ]
        },
        {
            "name": "Information och dialog",
            "factors": [
                "dialog_with_businesses",
                "information_to_businesses",
                "initiatives_from_businesses"
            ]
        },
        {
            "name": "Förutsättningar för företagande",
            "factors": [
                "access_to_competent_labour",
                "roads_railways_airports",
                "telecom_and_it_networks"
            ]
        },
        {
            "name": "Brottslighet mot företag",
            "factors": [
                "crime_a_problem_own_business"
            ]
        },
        {
            "name": "Årets extrafrågor",
            "factors": [
                "muni_contact_hesitate",
                "company_relocate",
            ]
        },
        {
            "name": "Tidigare extrafrågor",
            "factors": [
                "company_education_contact",
                "company_education_available",
                "muni_measures_view",
                "knowledge_of_muni_work",
                "business_climate_change_5_years",
                "survey_contact_clearfee",
                "survey_contact_digitized",
                "municipality_runs_competing_businesses",
                "crime_a_problem",
                "crime_change_2years",
                "crime_risk_kind"
            ]
        },
        {
            "name": "Tidigare ordinarie frågor",
            "factors": [
                "application_of_laws",
                "attitudes_media"
            ]
        }
    ],
    "statistics": [
        {
            "name": "Befolkning",
            "factors": [
                "employment_level_daytime_population",
                "employment_level",
                "population",
                "net_population_change",
                "retirees",
                "higher_education",
                "unemployment"
            ]
        },
        {
            "name": "Företagande",
            "factors": [
                "entrepreneurship",
                "new_entrepreneurship",
                "amount_of_business",
                "amount_of_business_absolute",
                "new_businesses",
                "young_entrepreneurs"
            ]
        },
        {
            "name": "Företagsstruktur",
            "factors": [
                "jobs_per_business",
                "employment_in_foreign_companies",
                "global_trade_per_capita",
                "largest_employers",
                "largest_private_employers"
            ]
        },
        {
            "name": "Kommunen",
            "factors": [
                "municipal_tax",
                "private_sector_employment",
                "contracting",
                "municipal_economy",
                "net_costs",
                "municipal_equalization",
                "ranking"
            ]
        }
    ],
    "ranking": [
        {
            "name": "Ranking",
            "factors": [
                "ranking"
            ]
        }
    ]
};
