"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FactorChartDescription = void 0;
var React = __importStar(require("react"));
var factors_1 = require("../../fixtures/factors");
exports.FactorChartDescription = React.memo(function (_a) {
    var factor = _a.factor, comparisonConfig = _a.comparisonConfig;
    var withGradingScale = comparisonConfig.withoutGradingScale !== true;
    var isSurvey = factor.source === "survey";
    var showGradingScale = isSurvey && withGradingScale;
    var gradingScaleType = comparisonConfig.gradingScaleType;
    var comp;
    var descriptionSpan = function (gradingScaleType) {
        var _a = factors_1.gradingScaleTypeDescription[gradingScaleType], preamble = _a.preamble, scaleDescription = _a.scaleDescription;
        return (React.createElement("span", { id: "js-grading-scale-1-6" }, "" + preamble + scaleDescription,
            "\u00A0"));
    };
    var readMore = React.createElement("p", { className: "own-row" },
        React.createElement("span", null,
            React.createElement("a", { href: "/om/fragorochsvar#about-survey", className: "view-more own-row" }, "L\u00E4s mer")));
    if (showGradingScale && gradingScaleType) {
        comp =
            React.createElement("div", { className: "text" },
                descriptionSpan(gradingScaleType),
                readMore);
    }
    else {
        comp = null;
    }
    return React.createElement("div", { className: "js-grading-scale scale" },
        React.createElement("div", { className: "title" },
            React.createElement("i", { className: "far fa-question-circle" }),
            factor.name),
        React.createElement("div", null, factor.long_description),
        factor.extra_description ? React.createElement("p", { className: "extra-description" },
            React.createElement("em", null, factor.extra_description)) : null,
        comp);
});
