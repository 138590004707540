"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toBarChartData = void 0;
var _ = __importStar(require("lodash"));
var barChartFactory_1 = require("./factories/barChartFactory");
var converterUtil_1 = require("./converterUtil");
function toBarChartData(chartData, chartService) {
    var _a, _b, _c, _d;
    var attributes = chartData.attributes, data = chartData.data;
    var datadesc = attributes.datadesc;
    var indexOfCategory = 0;
    var seriesLegendNames = _.map(datadesc, function (cdd) { return cdd.legend; });
    var seriesData = _.map(data.data, function (entry) {
        var _a;
        var _b;
        var category = ((_b = entry[indexOfCategory]) !== null && _b !== void 0 ? _b : "").toString();
        var categoryField = (_a = {}, _a[barChartFactory_1.Category] = category, _a);
        var zipped = _.zip(seriesLegendNames, entry);
        var dataSeriesFields = {};
        _.forEach(zipped, function (_a, index) {
            var seriesName = _a[0], value = _a[1];
            if (index !== indexOfCategory) {
                dataSeriesFields[seriesName] = value;
            }
        });
        return _.assign({}, categoryField, dataSeriesFields);
    });
    var sorting = chartService.sorting;
    if ((sorting === null || sorting === void 0 ? void 0 : sorting.sortOrder) === 'value') {
        // just pick first series name (Municipality)
        var key_1 = _.chain(seriesData).first().keys().find(function (k) { return k != barChartFactory_1.Category; }).value();
        seriesData = _.sortBy(seriesData, function (e) { return e[key_1]; });
        if (sorting.sortDir === 'asc') {
            seriesData = seriesData.reverse();
        }
    }
    else {
        var xscales_1 = chartService.xScales;
        seriesData = xscales_1
            ? _.sortBy(seriesData, function (d) { return -xscales_1.indexOf(d[barChartFactory_1.Category]); })
            : _.sortBy(seriesData, function (d) { return d[barChartFactory_1.Category]; }).reverse();
    }
    var unitExcludeFilter = chartService.unitExcludeFilter;
    var seriesAttributes = converterUtil_1.seriesAttributesFromDataDesc(datadesc, function (index) { return index !== indexOfCategory; }, function (unit) { return unitExcludeFilter(unit); });
    var legend = (_a = chartData.attributes.datadesc.find(function (_cdd, index) { return index === indexOfCategory; })) === null || _a === void 0 ? void 0 : _a.legend;
    var subtitle = chartData.attributes.subtitle.toUpperCase();
    if (legend) {
        subtitle = subtitle.replace(")", ", " + legend + ")");
    }
    var year = (_c = (_b = chartData.attributes.subtitle) === null || _b === void 0 ? void 0 : _b.match(/[0-9]{4}/g)) === null || _c === void 0 ? void 0 : _c[0];
    return {
        category: barChartFactory_1.Category,
        data: seriesData,
        seriesAttributes: seriesAttributes,
        title: (_d = chartService.titleOverride) !== null && _d !== void 0 ? _d : attributes.title,
        topTitle: subtitle,
        year: year,
        sources: attributes.sources
    };
}
exports.toBarChartData = toBarChartData;
