"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GuidesService = void 0;
var GuidesService = /** @class */ (function () {
    function GuidesService(guides) {
        this.guides = guides;
    }
    GuidesService.prototype.getTitle = function (guideSlug) {
        return this.guides[guideSlug].title;
    };
    return GuidesService;
}());
exports.GuidesService = GuidesService;
