"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListItem = void 0;
var markSubstringMatches_1 = require("../lib/markSubstringMatches");
var React = __importStar(require("react"));
exports.ListItem = function (_a) {
    var slug = _a.slug, isSelected = _a.isSelected, isVisible = _a.isVisible, filterList = _a.filterList, nameFromSlug = _a.nameFromSlug, setSelectedSlug = _a.setSelectedSlug;
    var setSelected = function () { return setSelectedSlug(slug); };
    return (React.createElement("li", { className: "factor-selection-list-item " + (isSelected ? "selected" : "") + " " + (isVisible ? '' : 'hidden'), onClick: setSelected },
        React.createElement(markSubstringMatches_1.MarkSubstringMatches, { str: nameFromSlug(slug), subStrings: filterList }),
        React.createElement("i", { className: "fas fa-chevron-right " + (isSelected ? "selected" : ""), "aria-hidden": "true" })));
};
