"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.miniPubSub = void 0;
var MiniPubSub = /** @class */ (function () {
    function MiniPubSub() {
        var _this = this;
        this.subscribers = {};
        this.subscribe = function (key, subscriber) {
            if (!_this.subscribers[key]) {
                _this.subscribers[key] = [];
            }
            _this.subscribers[key] = _this.subscribers[key].filter(function (s) { return s !== subscriber; }).concat(subscriber);
        };
        this.notify = function (key, n) {
            var _a, _b;
            ((_b = (_a = _this.subscribers) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : []).forEach(function (s) { return s(n); });
        };
    }
    return MiniPubSub;
}());
exports.miniPubSub = new MiniPubSub();
