"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SKR = void 0;
exports.SKR = {
    "A": {
        "description": "A. Storstäder och storstadsnära kommuner",
        "children": {
            "A1": {
                "name": "A1. Storstäder",
                "description": "Storstäder - kommuner med minst 200 000 invånare varav minst 200 000 invånare i den största tätorten.",
                "municipalities": [
                    "Göteborg",
                    "Malmö",
                    "Stockholm"
                ]
            },
            "A2": {
                "name": "A2. Pendlingskommun nära storstad",
                "description": "Pendlingskommun nära storstad - kommuner där minst 40 procent av nattbefolkningen pendlar till arbete i en storstad eller storstadsnära kommun.",
                "municipalities": [
                    "Ale",
                    "Alingsås",
                    "Bollebygd",
                    "Botkyrka",
                    "Burlöv",
                    "Danderyd",
                    "Ekerö",
                    "Haninge",
                    "Huddinge",
                    "Håbo",
                    "Härryda",
                    "Järfälla",
                    "Kungsbacka",
                    "Kungälv",
                    "Kävlinge",
                    "Lerum",
                    "Lidingö",
                    "Lilla Edet",
                    "Lomma",
                    "Mölndal",
                    "Nacka",
                    "Nynäshamn",
                    "Partille",
                    "Salem",
                    "Sigtuna",
                    "Skurup",
                    "Sollentuna",
                    "Solna",
                    "Staffanstorp",
                    "Stenungsund",
                    "Sundbyberg",
                    "Svedala",
                    "Trelleborg",
                    "Tyresö",
                    "Täby",
                    "Upplands Väsby",
                    "Upplands-Bro",
                    "Vallentuna",
                    "Vaxholm",
                    "Vellinge",
                    "Värmdö",
                    "Öckerö",
                    "Österåker"
                ]
            }
        }
    },
    "B": {
        "description": "B. Större städer och kommuner nära större stad",
        "children": {
            "B3": {
                "name": "B3. Större stad",
                "description": "Större stad - kommuner med minst 50 000 invånare varav minst 40 000 invånare i den största tätorten.",
                "municipalities": [
                    "Borlänge",
                    "Borås",
                    "Eskilstuna",
                    "Gävle",
                    "Halmstad",
                    "Helsingborg",
                    "Jönköping",
                    "Kalmar",
                    "Karlstad",
                    "Kristianstad",
                    "Linköping",
                    "Luleå",
                    "Lund",
                    "Norrköping",
                    "Sundsvall",
                    "Södertälje",
                    "Trollhättan",
                    "Umeå",
                    "Uppsala",
                    "Västerås",
                    "Växjö",
                    "Örebro",
                    "Östersund"
                ]
            },
            "B4": {
                "name": "B4. Pendlingskommun nära större stad",
                "description": "Pendlingskommun nära större stad - kommuner där minst 40 procent av nattbefolkningen pendlar till arbete i en större stad.",
                "municipalities": [
                    "Alvesta",
                    "Aneby",
                    "Bjurholm",
                    "Bjuv",
                    "Bromölla",
                    "Enköping",
                    "Eslöv",
                    "Forshaga",
                    "Gagnef",
                    "Gnesta",
                    "Grums",
                    "Grästorp",
                    "Habo",
                    "Hallsberg",
                    "Hallstahammar",
                    "Hammarö",
                    "Heby",
                    "Herrljunga",
                    "Höganäs",
                    "Hörby",
                    "Kil",
                    "Kinda",
                    "Klippan",
                    "Knivsta",
                    "Krokom",
                    "Kumla",
                    "Laholm",
                    "Landskrona",
                    "Lekeberg",
                    "Lessebo",
                    "Mark",
                    "Mjölby",
                    "Mullsjö",
                    "Mörbylånga",
                    "Nora",
                    "Nordmaling",
                    "Nykvarn",
                    "Ockelbo",
                    "Perstorp",
                    "Robertsfors",
                    "Sala",
                    "Sjöbo",
                    "Strängnäs",
                    "Surahammar",
                    "Svalöv",
                    "Svenljunga",
                    "Säter",
                    "Söderköping",
                    "Sölvesborg",
                    "Tierp",
                    "Timrå",
                    "Torsås",
                    "Trosa",
                    "Vaggeryd",
                    "Valdemarsvik",
                    "Vänersborg",
                    "Vännäs",
                    "Åstorp",
                    "Åtvidaberg",
                    "Älvkarleby",
                    "Ängelholm",
                    "Örkelljunga",
                    "Östra Göinge"
                ]
            },
            "B5": {
                "name": "B5. Lågpendlingskommun nära större stad",
                "description": "Lågpendlingskommun nära större stad - kommuner där mindre än 40 procent av nattbefolkningen pendlar till arbete i en större stad.",
                "municipalities": [
                    "Askersund",
                    "Berg",
                    "Boden",
                    "Bräcke",
                    "Finspång",
                    "Hylte",
                    "Hässleholm",
                    "Kristinehamn",
                    "Köping",
                    "Laxå",
                    "Lindesberg",
                    "Motala",
                    "Munkfors",
                    "Nybro",
                    "Nässjö",
                    "Sandviken",
                    "Tingsryd",
                    "Tranemo",
                    "Uddevalla",
                    "Ulricehamn",
                    "Uppvidinge",
                    "Vindeln",
                    "Älvsbyn",
                    "Östhammar"
                ]
            }
        }
    },
    "C": {
        "description": "C. Mindre städer/tätorter och landsbygdskommuner",
        "children": {
            "C6": {
                "name": "C6. Mindre stad/tätort",
                "description": "Mindre stad/tätort - kommuner med minst 15 000 men mindre än 40 000 invånare i den största tätorten.",
                "municipalities": [
                    "Avesta",
                    "Falkenberg",
                    "Falköping",
                    "Falun",
                    "Gotland",
                    "Hudiksvall",
                    "Härnösand",
                    "Karlshamn",
                    "Karlskoga",
                    "Karlskrona",
                    "Katrineholm",
                    "Kiruna",
                    "Lidköping",
                    "Ljungby",
                    "Ludvika",
                    "Mariestad",
                    "Norrtälje",
                    "Nyköping",
                    "Oskarshamn",
                    "Piteå",
                    "Skellefteå",
                    "Skövde",
                    "Varberg",
                    "Värnamo",
                    "Västervik",
                    "Ystad",
                    "Örnsköldsvik"
                ]
            },
            "C7": {
                "name": "C7. Pendlingskommun nära mindre stad/tätort",
                "description": "Pendlingskommun nära mindre stad/tätort - kommuner där minst 30 procent av nattbefolkningen pendlar till arbete i annan mindre ort och/eller där minst 30 procent av den sysselsatta dagbefolkningen bor i annan kommun.",
                "municipalities": [
                    "Arboga",
                    "Boxholm",
                    "Dals-Ed",
                    "Degerfors",
                    "Eksjö",
                    "Emmaboda",
                    "Essunga",
                    "Fagersta",
                    "Flen",
                    "Färgelanda",
                    "Gnosjö",
                    "Gullspång",
                    "Götene",
                    "Hedemora",
                    "Hjo",
                    "Hofors",
                    "Hultsfred",
                    "Högsby",
                    "Höör",
                    "Karlsborg",
                    "Kungsör",
                    "Ljusnarsberg",
                    "Markaryd",
                    "Mellerud",
                    "Munkedal",
                    "Mönsterås",
                    "Norberg",
                    "Nordanstig",
                    "Olofström",
                    "Orust",
                    "Osby",
                    "Oxelösund",
                    "Ronneby",
                    "Skara",
                    "Skinnskatteberg",
                    "Smedjebacken",
                    "Storfors",
                    "Sävsjö",
                    "Tibro",
                    "Tidaholm",
                    "Tjörn",
                    "Tomelilla",
                    "Töreboda",
                    "Vadstena",
                    "Vara",
                    "Vingåker",
                    "Vårgårda",
                    "Ydre",
                    "Åmål",
                    "Älmhult",
                    "Ödeshög"
                ]
            },
            "C8": {
                "name": "C8. Landsbygdskommun",
                "description": "Landsbygdskommun - kommuner med mindre än 15 000 invånare i den största tätorten, lågt pendlingsmönster (mindre än 30 procent).",
                "municipalities": [
                    "Arvidsjaur",
                    "Arvika",
                    "Bengtsfors",
                    "Bollnäs",
                    "Dorotea",
                    "Filipstad",
                    "Gislaved",
                    "Hagfors",
                    "Haparanda",
                    "Hällefors",
                    "Kalix",
                    "Kramfors",
                    "Ljusdal",
                    "Lysekil",
                    "Lycksele",
                    "Malå",
                    "Norsjö",
                    "Ovanåker",
                    "Pajala",
                    "Ragunda",
                    "Sollefteå",
                    "Strömsund",
                    "Sunne",
                    "Säffle",
                    "Söderhamn",
                    "Torsby",
                    "Tranås",
                    "Vansbro",
                    "Vetlanda",
                    "Vilhelmina",
                    "Vimmerby",
                    "Ånge",
                    "Åsele",
                    "Överkalix",
                    "Övertorneå"
                ]
            },
            "C9": {
                "name": "C9. Landsbygdskommun med besöksnäring",
                "description": "Landsbygdskommun med besöksnäring - landsbygdskommun med minst två kriterier för besöksnäring, dvs antal gästnätter, omsättning inom detaljhandel/ hotell/ restaurang i förhållande till invånarantalet.",
                "municipalities": [
                    "Arjeplog",
                    "Borgholm",
                    "Båstad",
                    "Eda",
                    "Gällivare",
                    "Härjedalen",
                    "Jokkmokk",
                    "Leksand",
                    "Malung-Sälen",
                    "Mora",
                    "Orsa",
                    "Rättvik",
                    "Simrishamn",
                    "Sorsele",
                    "Sotenäs",
                    "Storuman",
                    "Strömstad",
                    "Tanum",
                    "Åre",
                    "Årjäng",
                    "Älvdalen"
                ]
            }
        }
    }
};
