"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Municipalities = exports.CountiesAndMunicipalities = exports.CountyNames = exports.Sweden = exports.Counties = void 0;
var _ = __importStar(require("lodash"));
exports.Counties = {
    "county": {
        "ab_stockholms_lan": [
            "Stockholms län",
            "Botkyrka",
            "Danderyd",
            "Ekerö",
            "Haninge",
            "Huddinge",
            "Järfälla",
            "Lidingö",
            "Nacka",
            "Norrtälje",
            "Nykvarn",
            "Nynäshamn",
            "Salem",
            "Sigtuna",
            "Sollentuna",
            "Solna",
            "Stockholm",
            "Sundbyberg",
            "Södertälje",
            "Tyresö",
            "Täby",
            "Upplands-Bro",
            "Upplands Väsby",
            "Vallentuna",
            "Vaxholm",
            "Värmdö",
            "Österåker"
        ],
        "c_uppsala_lan": [
            "Uppsala län",
            "Enköping",
            "Heby",
            "Håbo",
            "Knivsta",
            "Tierp",
            "Uppsala",
            "Älvkarleby",
            "Östhammar"
        ],
        "d_sodermanlands_lan": [
            "Södermanlands län",
            "Eskilstuna",
            "Flen",
            "Gnesta",
            "Katrineholm",
            "Nyköping",
            "Oxelösund",
            "Strängnäs",
            "Trosa",
            "Vingåker"
        ],
        "e_ostergotlands_lan": [
            "Östergötlands län",
            "Boxholm",
            "Finspång",
            "Kinda",
            "Linköping",
            "Mjölby",
            "Motala",
            "Norrköping",
            "Söderköping",
            "Vadstena",
            "Valdemarsvik",
            "Ydre",
            "Åtvidaberg",
            "Ödeshög"
        ],
        "f_jonkopings_lan": [
            "Jönköpings län",
            "Aneby",
            "Eksjö",
            "Gislaved",
            "Gnosjö",
            "Habo",
            "Jönköping",
            "Mullsjö",
            "Nässjö",
            "Sävsjö",
            "Tranås",
            "Vaggeryd",
            "Vetlanda",
            "Värnamo"
        ],
        "g_kronobergs_lan": [
            "Kronobergs län",
            "Alvesta",
            "Lessebo",
            "Ljungby",
            "Markaryd",
            "Tingsryd",
            "Uppvidinge",
            "Växjö",
            "Älmhult"
        ],
        "h_kalmar_lan": [
            "Kalmar län",
            "Borgholm",
            "Emmaboda",
            "Hultsfred",
            "Högsby",
            "Kalmar",
            "Mönsterås",
            "Mörbylånga",
            "Nybro",
            "Oskarshamn",
            "Torsås",
            "Vimmerby",
            "Västervik"
        ],
        "i_gotlands_lan": [
            "Gotlands län",
            "Gotland"
        ],
        "k_blekinge_lan": [
            "Blekinge län",
            "Karlshamn",
            "Karlskrona",
            "Olofström",
            "Ronneby",
            "Sölvesborg"
        ],
        "m_skane_lan": [
            "Skåne län",
            "Bjuv",
            "Bromölla",
            "Burlöv",
            "Båstad",
            "Eslöv",
            "Helsingborg",
            "Hässleholm",
            "Höganäs",
            "Hörby",
            "Höör",
            "Klippan",
            "Kristianstad",
            "Kävlinge",
            "Landskrona",
            "Lomma",
            "Lund",
            "Malmö",
            "Osby",
            "Perstorp",
            "Simrishamn",
            "Sjöbo",
            "Skurup",
            "Staffanstorp",
            "Svalöv",
            "Svedala",
            "Tomelilla",
            "Trelleborg",
            "Vellinge",
            "Ystad",
            "Åstorp",
            "Ängelholm",
            "Örkelljunga",
            "Östra Göinge"
        ],
        "n_hallands_lan": [
            "Hallands län",
            "Falkenberg",
            "Halmstad",
            "Hylte",
            "Kungsbacka",
            "Laholm",
            "Varberg"
        ],
        "o_vastra_gotalands_lan": [
            "Västra Götalands län",
            "Ale",
            "Alingsås",
            "Bengtsfors",
            "Bollebygd",
            "Borås",
            "Dals-Ed",
            "Essunga",
            "Falköping",
            "Färgelanda",
            "Grästorp",
            "Gullspång",
            "Göteborg",
            "Götene",
            "Herrljunga",
            "Hjo",
            "Härryda",
            "Karlsborg",
            "Kungälv",
            "Lerum",
            "Lidköping",
            "Lilla Edet",
            "Lysekil",
            "Mariestad",
            "Mark",
            "Mellerud",
            "Munkedal",
            "Mölndal",
            "Orust",
            "Partille",
            "Skara",
            "Skövde",
            "Sotenäs",
            "Stenungsund",
            "Strömstad",
            "Svenljunga",
            "Tanum",
            "Tibro",
            "Tidaholm",
            "Tjörn",
            "Tranemo",
            "Trollhättan",
            "Töreboda",
            "Uddevalla",
            "Ulricehamn",
            "Vara",
            "Vårgårda",
            "Vänersborg",
            "Åmål",
            "Öckerö"
        ],
        "s_varmlands_lan": [
            "Värmlands län",
            "Arvika",
            "Eda",
            "Filipstad",
            "Forshaga",
            "Grums",
            "Hagfors",
            "Hammarö",
            "Karlstad",
            "Kil",
            "Kristinehamn",
            "Munkfors",
            "Storfors",
            "Sunne",
            "Säffle",
            "Torsby",
            "Årjäng"
        ],
        "t_orebro_lan": [
            "Örebro län",
            "Askersund",
            "Degerfors",
            "Hallsberg",
            "Hällefors",
            "Karlskoga",
            "Kumla",
            "Laxå",
            "Lekeberg",
            "Lindesberg",
            "Ljusnarsberg",
            "Nora",
            "Örebro"
        ],
        "u_vastmanlands_lan": [
            "Västmanlands län",
            "Arboga",
            "Fagersta",
            "Hallstahammar",
            "Kungsör",
            "Köping",
            "Norberg",
            "Sala",
            "Skinnskatteberg",
            "Surahammar",
            "Västerås"
        ],
        "w_dalarnas_lan": [
            "Dalarnas län",
            "Avesta",
            "Borlänge",
            "Falun",
            "Gagnef",
            "Hedemora",
            "Leksand",
            "Ludvika",
            "Malung-Sälen",
            "Mora",
            "Orsa",
            "Rättvik",
            "Smedjebacken",
            "Säter",
            "Vansbro",
            "Älvdalen"
        ],
        "x_gavleborgs_lan": [
            "Gävleborgs län",
            "Bollnäs",
            "Gävle",
            "Hofors",
            "Hudiksvall",
            "Ljusdal",
            "Nordanstig",
            "Ockelbo",
            "Ovanåker",
            "Sandviken",
            "Söderhamn"
        ],
        "y_vasternorrlands_lan": [
            "Västernorrlands län",
            "Härnösand",
            "Kramfors",
            "Sollefteå",
            "Sundsvall",
            "Timrå",
            "Ånge",
            "Örnsköldsvik"
        ],
        "z_jamtlands_lan": [
            "Jämtlands län",
            "Berg",
            "Bräcke",
            "Härjedalen",
            "Krokom",
            "Ragunda",
            "Strömsund",
            "Åre",
            "Östersund"
        ],
        "ac_vasterbottens_lan": [
            "Västerbottens län",
            "Bjurholm",
            "Dorotea",
            "Lycksele",
            "Malå",
            "Nordmaling",
            "Norsjö",
            "Robertsfors",
            "Skellefteå",
            "Sorsele",
            "Storuman",
            "Umeå",
            "Vilhelmina",
            "Vindeln",
            "Vännäs",
            "Åsele"
        ],
        "bd_norrbottens_lan": [
            "Norrbottens län",
            "Arjeplog",
            "Arvidsjaur",
            "Boden",
            "Gällivare",
            "Haparanda",
            "Jokkmokk",
            "Kalix",
            "Kiruna",
            "Luleå",
            "Pajala",
            "Piteå",
            "Älvsbyn",
            "Överkalix",
            "Övertorneå"
        ]
    }
};
exports.Sweden = "Sverige";
// set of string names of _counties_
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion,@typescript-eslint/no-extra-non-null-assertion
exports.CountyNames = _.map(exports.Counties.county, function (county_is_first) { return _.first(county_is_first); }).sort();
exports.CountiesAndMunicipalities = _.flatMap(exports.Counties.county, function (value) { return value; }).sort();
exports.Municipalities = _.difference(exports.CountiesAndMunicipalities, exports.CountyNames);
