"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toStringArray = void 0;
function toStringArray(val) {
    if (typeof val == 'string') {
        return [val];
    }
    else if (typeof val == "number") {
        return [val.toString()];
    }
    else if (val === undefined) {
        return [];
    }
    else {
        return val;
    }
}
exports.toStringArray = toStringArray;
